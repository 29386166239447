import React, { useEffect, useState, useRef } from 'react'
import AttachmentCard from './Components/AttachmentCard/Index'
import { ButtonAddAttachment, ButtonListAttachment } from './styles'
import { getListAttachments } from './core/_request'
import { Spinner } from 'react-bootstrap'
import { createAttachment } from './core/_request'
import Swal from 'sweetalert2'
const AttachmentComponent = ({ entityData, entity }: any) => {
  const [attachmentsData, setAttachmentsData] = useState<any>({
    data: [],
    pagination: {},
  })
  const [showLoading, setshowLoading] = useState(false)
  const inputFileRef = useRef<any>(null)

  const loadDataPage = async (page = 1, sizePerPage = 5) => {
    try {
      let query = `${entity}Id=${entityData?.id}&page=${page}&perPage=${sizePerPage}&include_user=true`
      let result = await getListAttachments(query)
      let newData = { ...attachmentsData }
      if (page > 1) {
        newData.data = newData?.data?.concat(result?.data)
      } else {
        newData.data = result?.data
      }
      newData.pagination = result.pagination
      setAttachmentsData(newData)
    } catch (e) {
      console.log(e)
    }
  }

  const handleStoreFile = async (file: any) => {
    setshowLoading(true)
    try {
      const formData = new FormData()
      formData.append(entity + "Id", entityData?.id)
      formData.append('file', file)
      let response = await createAttachment(formData)
      Swal.fire({
        title: 'Arquivo anexado com sucesso!',
        icon: 'success',
      })
      addOnState(response?.url)
    } catch (e) {
      console.log(e)
    }
    setshowLoading(false)
  }
  const addOnState = (obj: any) => {
    let newData = { ...attachmentsData }
    newData?.data?.push(obj)
    setAttachmentsData(newData)
  }

  useEffect(() => {
    loadDataPage()
  }, [])

  return (
    <React.Fragment>
      <h3 className='mt-3 label-card'>Anexos</h3>
      {attachmentsData?.data?.length <= 0 ? (
        <>
          <div className='label-card'>Sem anexos até o momento.</div>
          <br />
        </>
      ) : (
        attachmentsData.data.map((item: any, index: number) => (
          <AttachmentCard
            key={'row' + index}
            attachmentId={item?.id}
            FileNameData={item?.fileName}
            CreatedAt={item?.createdAt}
            sizeAndInfoUser={`xkb * Enviado por ${item.user?.name}`}
            loadDataPage={loadDataPage}
            attachmentsData={attachmentsData}
            setAttachmentsData={setAttachmentsData}
          />
        ))
      )}
      <input
        type='file'
        onChange={(e: any) => {
          handleStoreFile(e?.target?.files[0])
        }}
        style={{ display: 'none' }}
        ref={inputFileRef}
      />
      <button
        type='button'
        className='btn-sm btn-wl-custom-primary-collor-inverse-border'
        onClick={() => {
          if (inputFileRef?.current && !showLoading) {
            inputFileRef?.current?.click()
          }
        }}
      >
        {showLoading ? (
          <>
            Enviando solicitação... <Spinner size='sm' style={{ fontWeight: 'bold' }} />
          </>
        ) : (
          'Adicionar Anexo'
        )}
      </button>

      {attachmentsData?.pagination?.page < attachmentsData?.pagination?.pagesCount ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          {' '}
          <button
            className='btn btn-sm btn-wl-custom-primary-collor'
            onClick={() => {
              const newPage = attachmentsData?.pagination?.page + 1
              loadDataPage(newPage)
            }}
          >
            Carregar Mais Anexos
          </button>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default AttachmentComponent
