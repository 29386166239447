import {createContext, useContext, useReducer} from 'react'

export interface IntegrationEmailFormModalContextModel {
  open: boolean
}

const initialData = {
  open: false,
}

const IntegrationEmailFormModalContext =
  createContext<IntegrationEmailFormModalContextModel>(initialData)
const IntegrationEmailFormModalDispatchContext = createContext<any>(null)

export function IntegrationEmailFormModalProvider({children}: any) {
  const [data, dispatch] = useReducer(IntegrationEmailFormModalReducer, initialData)

  return (
    <IntegrationEmailFormModalContext.Provider value={data}>
      <IntegrationEmailFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </IntegrationEmailFormModalDispatchContext.Provider>
    </IntegrationEmailFormModalContext.Provider>
  )
}

export function useIntegrationEmailFormModal() {
  return useContext(IntegrationEmailFormModalContext)
}

export function useIntegrationEmailFormModalDispatch() {
  return useContext(IntegrationEmailFormModalDispatchContext)
}

function IntegrationEmailFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return {...data, open: true}
    }
    case 'close-modal': {
      return {...data, open: false}
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}
