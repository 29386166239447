import {KTIcon} from '../../../../_metronic/helpers'
import {useListView} from '../../../modules/view/ListViewProvider'

type Props = {
  funnelStage: any,
  onCloseModal: any
}

const FunnelsEditModalHeader = ({funnelStage, onCloseModal}: Props) => {

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{funnelStage ? "Alterar" : "Adicionar"} Etapa</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm '
        data-kt-users-modal-action='close'
        onClick={() => onCloseModal()}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' iconType='solid' className='fs-1 cross'/>
      </div>
      {/* end::Close */}
    </div>
  )
}

export {FunnelsEditModalHeader}
