
import React, { FC } from 'react';
interface IntegrationHeaderProps {
    userConfig?: boolean;
  }
  
  const IntegrationHeader: FC<IntegrationHeaderProps> = ({ userConfig }) => {
    return (
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div id="kt_app_toolbar_container" className="d-flex flex-stack container-fluid">

                <div id="kt_page_title" data-kt-swapper="true" data-kt-swapper-mode="prepend"
                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                    className="page-title d-flex flex-wrap me-3 flex-row justify-content-center">
                    <i className="fa-solid fa-filter fs-2 me-1"></i><h3><strong>{userConfig?'Configurações':'Integrações'}</strong></h3>
                </div>
            </div>
        </div>
    )
}

export { IntegrationHeader }
