import {useState, useEffect} from 'react'
import {InputFieldSet} from '../../../../../../_metronic/partials/inputs/InputFieldSet'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import {renderTimeViewClock} from '@mui/x-date-pickers/timeViewRenderers'
import Form from 'react-bootstrap/Form'
import SelectAsync from 'react-select/async'
import Select from 'react-select'
import {FieldArray, Formik, Field, useFormikContext} from 'formik'
import {getFunnelStages} from '../../../../funnel/core/_requests'
import {getContacts, getCompanies} from '../../../../cards/core/_requests'
import styled from 'styled-components'
import PhoneField from '../../../../../../_metronic/helpers/custom-inputs/PhoneField'
import CpfCnpjField from '../../../../../../_metronic/helpers/custom-inputs/CpfCnpjField'
import debounce from 'debounce-promise'
import {Dropdown} from 'react-bootstrap'
import {JsonFlags} from '../../../../../../_metronic/helpers/JsonFlags'
import {NumericFormat} from 'react-number-format'
import PhoneFieldNT from '../../../../../../_metronic/helpers/custom-inputs/PhoneFieldNT'
const CustomDropdownToggle = styled(Dropdown.Toggle)`
  height: 20px;
  width: 55px;
  position: relative;
  top: -10px;
  left: -11px;
  background-color: transparent !important;
  color: black !important;
`
const CustomDropdownMenu = styled(Dropdown.Menu)`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 12px;
  margin-left: 3px;
`
const StyledDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    height: 20px;
    padding: 6px 0;
    padding-left: 13px;
    font-size: 14px;
  }

  & .MuiInputBase-root {
    height: auto;
    min-height: 38px;
  }
`

const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    height: '20px',
    minHeight: '20px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  //   dropdownIndicator: (base:any) => ({
  //     ...base,
  //     padding: '3px',
  //     fontSize: '1rem'
  //   })
}

const FunnelStepField = (props: any) => {
  const fieldName = props.field.name

  const {
    values: {funnelId},
    setFieldValue,
  } = useFormikContext<any>()

  const [stages, setStages] = useState<any>([])

  useEffect(() => {
    let loadStages = async () => {
      try {
        let list: Array<any> = []
        if (funnelId?.value) {
          let funnelStages = await getFunnelStages(funnelId.value, 'page=1&perPage=100')
          if (funnelStages && funnelStages != undefined) {
            for (let i = 0; i < funnelStages?.length; i++) {
              list.push({
                label: funnelStages[i].title,
                value: funnelStages[i].id,
                funnelId: funnelId.value,
              })
            }
          }
        }
        setStages(list)

        if (!props?.value?.value) {
          setFieldValue(fieldName, {label: list[0]?.label, value: list[0]?.value})
        }
      } catch (e) {
        console.log(e)
      }
    }

    if (props.value?.funnelId && props.value.funnelId != funnelId?.value) {
      setFieldValue(fieldName, null)
    }

    loadStages()
  }, [funnelId, funnelId?.value])

  return (
    <InputFieldSet label={props.label ? props.label : 'Etapa do Funil'}>
      <Select
        menuPortalTarget={document.body}
        placeholder={''}
        name={fieldName}
        options={stages}
        value={props.value}
        isDisabled={props.disabled}
        onChange={(selectedOption) => {
          setFieldValue(fieldName, selectedOption ? selectedOption : undefined)
        }}
        className='fieldset-input'
        styles={customStyles}
      />
    </InputFieldSet>
  )
}

const CompanyField = (props: any) => {
  //Necessário o relacionamento entre os campos company e contact para os resultados da busca serem vinculados aos campos
  //Ao buscar contatos com uma company selecionada, o resultado da busca deve ser filtrado pela company selecionada
  //Ao buscar empresas com um contact selecionado, o resultado da busca deve ser filtrado pelo contact selecionado

  const fieldName = props.field.name

  const {
    values: {contactId},
    setFieldValue,
  } = useFormikContext<any>()

  const companiesOptions = (inputValue: string, contact_id?: any) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let companies = await getCompanies(
        'page=1&perPage=20&search=' +
          encodeURIComponent(inputValue) +
          (contact_id ? `&include_contact=true&contactId=${contact_id}` : '')
      )
      if (companies && companies.data != undefined) {
        for (let i = 0; i < companies?.data?.length; i++) {
          list.push({
            label: companies.data[i].name,
            value: companies.data[i].id,
            contact: contact_id,
          })
        }
      }
      resolve(list)
    })

  const debounceCompaniesOptions = debounce(companiesOptions, 500)

  const auxCompaniesOptions = (inputValue: string) => {
    return debounceCompaniesOptions(inputValue, contactId ? contactId.value : undefined)
  }

  useEffect(() => {
    if (
      contactId != undefined &&
      props.value &&
      contactId?.companyId &&
      contactId?.companyId != props.value.value
    ) {
      setFieldValue(fieldName, null)
    }
  }, [contactId, contactId?.value])

  return (
    <InputFieldSet label={props.label ? props.label : 'Empresa'} key={contactId?.companyId}>
      {' '}
      {/** Key Necessária para quando existir campo contact, para não precisar re-renderizar o campo company qnd for contato da mesma empresa do contato anterior */}
      <SelectAsync
        menuPortalTarget={document.body}
        placeholder={''}
        name={fieldName}
        loadOptions={auxCompaniesOptions}
        value={props.value}
        isDisabled={props.disabled}
        cacheOptions
        isClearable
        defaultOptions
        onChange={(selectedOption: any) => {
          setFieldValue(fieldName, selectedOption ? selectedOption : null)
        }}
        className='fieldset-input'
        styles={customStyles}
      />
    </InputFieldSet>
  )
}

const ContactField = (props: any) => {
  const fieldName = props.field.name

  //Necessário o relacionamento entre os campos company e contact para os resultados da busca serem vinculados aos campos
  //Ao buscar contatos com uma company selecionada, o resultado da busca deve ser filtrado pela company selecionada
  //Ao buscar empresas com um contact selecionado, o resultado da busca deve ser filtrado pelo contact selecionado

  const {
    values: {companyId},
    setFieldValue,
  } = useFormikContext<any>()

  const contactsOptions = (inputValue: string, company_id?: any) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let contacts = await getContacts(
        'page=1&perPage=10&include_company=true&search=' +
          encodeURIComponent(inputValue) +
          (company_id ? `&companyId=${company_id}` : '')
      )
      if (contacts && contacts.data != undefined) {
        for (let i = 0; i < contacts?.data?.length; i++) {
          list.push({
            label: contacts.data[i].name,
            value: contacts.data[i].id,
            companyId: contacts.data[i].company?.id,
          })
        }
      }
      resolve(list)
    })

  const debounceContactsOptions = debounce(contactsOptions, 500)

  const auxContactsOptions = (inputValue: string) => {
    return debounceContactsOptions(inputValue, companyId ? companyId.value : undefined)
  }

  useEffect(() => {
    if (
      companyId != undefined &&
      props.value?.companyId &&
      props.value.companyId != companyId?.value
    ) {
      setFieldValue(fieldName, null)
    }
  }, [companyId, companyId?.value])

  return (
    <InputFieldSet label={props.label ? props.label : 'Contato'} key={companyId?.value}>
      <SelectAsync
        menuPortalTarget={document.body}
        placeholder={''}
        name={fieldName}
        loadOptions={auxContactsOptions}
        value={props.value}
        isDisabled={props.disabled}
        cacheOptions
        isClearable
        defaultOptions
        onChange={(selectedOption) =>
          setFieldValue(fieldName, selectedOption ? selectedOption : undefined)
        }
        className='fieldset-input'
        styles={customStyles}
      />
    </InputFieldSet>
  )
}

const FieldTypeInput = ({
  field,
  className,
  disabled = false,
  onChange,
  onBlur,
  value,
  style,
  selectLoadOptions,
  selectOptions,
  setFieldValue,
  checked,
  renderMode,
  useSelectOptionLabel,
  useOnChange,
}: {
  field: any
  className?: string
  disabled?: boolean
  onChange?: any
  onBlur?: any
  value?: any
  style?: any
  selectLoadOptions?: any
  selectOptions?: any
  setFieldValue?: any
  checked?: boolean
  renderMode?: string
  useSelectOptionLabel?: boolean
  useOnChange?: boolean
}) => {
  let input = <></>
  let customClassName = className ? className : ''
  // if(field.type?.key == "switch") console.log("value", value);

  const loadDdiPhoneImage = () => {
    let img: any = null
    let phoneNumber = ''
    if (value && value != '') {
      try {
        phoneNumber = value?.replace(/[^\d]/g, '')
      } catch (e) {}
    }

    if (phoneNumber && phoneNumber != '') {
      try {
        for (let i = 0; i < JsonFlags?.length; i++) {
          let ddiPhone = null
          let flag = JsonFlags[i]
          let ddi = flag?.ddi?.toString()
          let tamFlagDdi = ddi?.length

          ddiPhone = phoneNumber?.substring(0, tamFlagDdi)

          if (ddi == ddiPhone) {
            img = {img: flag.img, ddi: ddi}
            break
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (!img) {
      if (!phoneNumber || phoneNumber == '') {
        img = {img: JsonFlags[0]?.img, ddi: JsonFlags[0]?.ddi?.toString()}
        setFieldValue(field?.name, '+' + JsonFlags[0]?.ddi?.toString())
      }
    }

    return img
  }

  const loadDdiPhonesImages = (name?: string, elementValue?: any) => {
    let img: any = null
    let phoneNumber = ''
    if (elementValue && elementValue != '') {
      try {
        phoneNumber = elementValue?.replace(/[^\d]/g, '')
      } catch (e) {}
    }

    if (phoneNumber && phoneNumber != '') {
      try {
        for (let i = 0; i < JsonFlags?.length; i++) {
          let ddiPhone = null
          let flag = JsonFlags[i]
          let ddi = flag?.ddi?.toString()
          let tamFlagDdi = ddi?.length

          ddiPhone = phoneNumber?.substring(0, tamFlagDdi)

          if (ddi == ddiPhone) {
            img = {img: flag.img, ddi: ddi}
            break
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (!img) {
      if (!phoneNumber || phoneNumber == '') {
        img = {img: JsonFlags[0]?.img, ddi: JsonFlags[0]?.ddi?.toString()}
        setFieldValue(name, '+' + JsonFlags[0]?.ddi?.toString())
      }
    }

    return img
  }

  let inputLabel = field.label

  if (field.required) {
    inputLabel = (
      <div>
        {field.label}{' '}
        <i
          className='fa-solid fa-asterisk'
          style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
        ></i>
      </div>
    )
  }

  //console.log("field", field);
  switch (field.type?.key) {
    case 'number':
      input = (
        <InputFieldSet label={inputLabel}>
          <input
            placeholder=''
            type='number'
            className={'form-control fieldset-input ' + customClassName}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            value={value ? value : 0}
            name={field.name ? field.name : undefined}
          />
        </InputFieldSet>
      )
      break
    case 'short_text':
      if ((field?.name == 'cpf' || field?.name == 'cnpj') && renderMode != 'editor') {
        input = (
          <InputFieldSet label={inputLabel}>
            <Field name={field.name ? field.name : undefined} component={CpfCnpjField} />
            {/* <CpfCnpjInput
              customClassName={'form-control fieldset-input ' + customClassName}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={field.name ? field.name : undefined}
            /> */}
          </InputFieldSet>
        )
      } else {
        input = (
          <InputFieldSet label={inputLabel}>
            <input
              placeholder=''
              type='text'
              className={'form-control fieldset-input ' + customClassName}
              disabled={disabled}
              onChange={onChange}
              onBlur={onBlur}
              value={value ? value : ''}
              name={field.name ? field.name : undefined}
            />
          </InputFieldSet>
        )
      }

      break
    case 'long_text':
      input = (
        <InputFieldSet label={inputLabel}>
          <textarea
            placeholder=''
            className={'form-control fieldset-input ' + customClassName}
            autoComplete='off'
            disabled={disabled}
            rows={4}
            onChange={onChange}
            onBlur={onBlur}
            value={value ? value : ''}
            name={field.name ? field.name : undefined}
          />
        </InputFieldSet>
      )
      break
    case 'date':
      input = (
        <DatePicker
          label={inputLabel}
          disabled={disabled}
          className={customClassName}
          value={value ? new Date(value) : null}
          onChange={(value) => setFieldValue(field.name, value)}
        />
      )
      break
    case 'datetime':
      input = (
        <StyledDateTimePicker
          label={inputLabel}
          disabled={disabled}
          value={value ? new Date(value) : null}
          onChange={(value: any) => setFieldValue(field.name, value)}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
        />
      )
      break
    case 'time':
      input = (
        <MobileTimePicker
          label={inputLabel}
          disabled={disabled}
          className={customClassName}
          value={value ? new Date(value) : null}
          onChange={(value) => setFieldValue(field.name, value)}
          slotProps={{toolbar: {hidden: true}}}
        />
      )
      break
    case 'phone':
      if (renderMode != 'editor') {
        input = (
          <InputFieldSet label={inputLabel}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {' '}
              <Dropdown>
                <CustomDropdownToggle variant='outline-secondary' id='dropdown-custom'>
                  <img
                    src={loadDdiPhoneImage()?.img}
                    alt=''
                    style={{width: '25px', height: '16px'}}
                  />
                  {/* <span style={{marginLeft: '5px'}}>
                    +{loadDdiPhoneImage()?.ddi}
                  </span> */}
                </CustomDropdownToggle>
                <CustomDropdownMenu>
                  {JsonFlags.map((item, index) => (
                    <div key={item?.pais + 'row'}>
                      <Dropdown.Item
                        href='#'
                        onClick={() => {
                          let newValue = '+' + item?.ddi?.toString() + ' '
                          setFieldValue(field?.name, newValue)
                        }}
                      >
                        {' '}
                        <img src={item?.img} style={{width: '25px', height: '16px'}} />{' '}
                        <span style={{marginLeft: '10px'}}>{item.pais}</span>{' '}
                        <span style={{marginLeft: '5px'}}>+{item.ddi}</span>
                      </Dropdown.Item>
                    </div>
                  ))}
                </CustomDropdownMenu>
              </Dropdown>
              <div style={{width: '80%'}}>
                {loadDdiPhoneImage()?.ddi == '55' ? (
                  <Field name={field?.name} component={PhoneField} setFieldValue={setFieldValue} />
                ) : (
                  <Field name={field.name ? field.name : undefined} component={PhoneFieldNT} />
                  // <div>
                  //   {' '}
                  //   <input
                  //     placeholder=''
                  //     type='text'
                  //     className={'form-control fieldset-input ' + customClassName}
                  //     disabled={disabled}
                  //     onChange={onChange}
                  //     onBlur={onBlur}
                  //     value={value ? value : ''}
                  //     maxLength={40}
                  //     name={field.name ? field.name : undefined}
                  //   />
                  // </div>
                )}
              </div>
            </div>
          </InputFieldSet>
          // <InputGroup style={{width: '100%'}}>
          //   <Dropdown>
          //     <CustomDropdownToggle variant='outline-secondary' id='dropdown-custom'>
          //       <img src={JsonFlags[0]?.img} style={{width: '25px', height: '16px'}} />
          //     </CustomDropdownToggle>

          //     <CustomDropdownMenu>
          //       {JsonFlags.map((item, index) => (
          //         <Dropdown.Item href='#'>
          //           {' '}
          //           <img src={item?.img} style={{width: '25px', height: '16px'}} />{' '}
          //           <span style={{marginLeft: '10px'}}>{item.pais}</span>{' '}
          //           <span style={{marginLeft: '5px'}}>+{item.ddi}</span>
          //         </Dropdown.Item>
          //       ))}
          //     </CustomDropdownMenu>
          //   </Dropdown>
          //   <div className='flex-grow-1'>
          //     {' '}
          //     <InputFieldSet label={inputLabel}>
          //       <Field name={field?.name} component={PhoneField} />
          //     </InputFieldSet>
          //   </div>
          // </InputGroup>
          // <InputFieldSet label={inputLabel}>
          //   <Field name={field?.name} component={PhoneField} />
          // </InputFieldSet>
        )
      } else {
        input = (
          <InputFieldSet label={inputLabel}>
            <div style={{display: 'flex', flexDirection: 'row', flexFlow: 'wrap'}}>
              {' '}
              <Dropdown>
                <CustomDropdownToggle variant='outline-secondary' id='dropdown-custom'>
                  <img src={JsonFlags[0]?.img} style={{width: '25px', height: '16px'}} />{' '}
                  <span style={{marginLeft: '5px'}}>+55</span>
                </CustomDropdownToggle>
              </Dropdown>
              <div>
                {' '}
                <input
                  placeholder=''
                  type='text'
                  className={'form-control fieldset-input ' + customClassName}
                  disabled={disabled}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value ? value : ''}
                  name={field.name ? field.name : undefined}
                />
              </div>
            </div>
          </InputFieldSet>

          // <InputGroup style={{width: '100%'}}>
          //   <Dropdown>
          //     <CustomDropdownToggle variant='outline-secondary' id='dropdown-custom'>
          //       <img src={JsonFlags[0]?.img} style={{width: '25px', height: '16px'}} />
          //     </CustomDropdownToggle>
          //   </Dropdown>
          //   <div className='flex-grow-1'>
          //     {' '}
          //     <InputFieldSet label={inputLabel}>
          //       <input
          //         placeholder=''
          //         type='text'
          //         className={'form-control fieldset-input ' + customClassName}
          //         disabled={disabled}
          //         onChange={onChange}
          //         onBlur={onBlur}
          //         value={value ? value : ''}
          //         name={field.name ? field.name : undefined}
          //       />
          //     </InputFieldSet>
          //   </div>
          // </InputGroup>
          // <InputFieldSet label={inputLabel}>
          //   <Field name={field?.name} component={PhoneField} />
          // </InputFieldSet>
        )
        // input = (
        //   <InputFieldSet label={inputLabel}>
        //     <input
        //       placeholder=''
        //       type='text'
        //       className={'form-control fieldset-input ' + customClassName}
        //       disabled={disabled}
        //       onChange={onChange}
        //       onBlur={onBlur}
        //       value={value ? value : ''}
        //       name={field.name ? field.name : undefined}
        //     />
        //   </InputFieldSet>
        // )
      }

      break
    case 'monetary':
      input = (
        <InputFieldSet label={inputLabel}>
          <NumericFormat
            name={field.name ? field.name : undefined}
            displayType={'input'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            fixedDecimalScale={true}
            value={value ? value : 0}
            prefix={'R$ '}
            className={'form-control fieldset-input ' + customClassName}
            onValueChange={(values: any) => setFieldValue(field?.name, values.floatValue)}
          />
          {/* <input
            placeholder=''
            type='number'
            className={'form-control fieldset-input ' + customClassName}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            value={value ? value : 0}
            name={field.name ? field.name : undefined}
          /> */}
        </InputFieldSet>
      )
      break
    case 'radio':
      let optionsRadio = field.options ? field.options : []
      input = (
        <>
          <div className='custom-radio-input d-flex flex-column gap-2'>
            <div>{field.label}</div>
            {optionsRadio.map((op: any, index: number) => {
              return (
                <Form.Check type={'radio'} id={field.name} key={index}>
                  <Form.Check.Input
                    type={'radio'}
                    value={value}
                    checked={value == op.value}
                    readOnly={disabled}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFieldValue(field.name, op.value)
                      }
                    }}
                    onBlur={onBlur}
                    name={field.name}
                  />
                  <Form.Check.Label>{op.value}</Form.Check.Label>
                </Form.Check>
              )
            })}
          </div>
        </>
      )
      break
    case 'checkbox':
      let optionsCheck = field.options ? field.options : []
      input = (
        <>
          <div className='custom-checkbox-input d-flex flex-column gap-2'>
            <div>{field.label}</div>
            {optionsCheck.map((op: any, index: number) => {
              let valueParsed: any = null
              try {
                if (value) valueParsed = JSON.parse(value)
              } catch (e) {
                console.log(e)
              }
              return (
                <Form.Check type={'checkbox'} id={field.name} key={index}>
                  <Form.Check.Input
                    type={'checkbox'}
                    value={value}
                    checked={value && valueParsed?.includes(op.value) ? true : false}
                    readOnly={disabled}
                    //onChange={onChange}
                    onChange={(e) => {
                      let newValue: any = []

                      if (value) {
                        newValue = valueParsed
                      }

                      if (e.target.checked) {
                        newValue.push(op.value)
                      } else {
                        newValue = newValue.filter((e: any) => e != op.value)
                      }

                      setFieldValue(field.name, JSON.stringify(newValue))
                    }}
                    onBlur={onBlur}
                    name={field.name}
                  />
                  <Form.Check.Label>{op.value}</Form.Check.Label>
                </Form.Check>
              )
            })}
          </div>
        </>
      )
      break
    case 'switch':
      input = (
        <Form.Check
          type={'switch'}
          id={field.name}
          reverse
          style={{textAlign: 'left', marginTop: '14px'}}
        >
          <Form.Check.Label style={{}}>
            <strong>{field.label}</strong>
          </Form.Check.Label>
          <Form.Check.Input
            value={value ? 'true' : 'false'}
            checked={value ? true : false}
            type={'checkbox'}
            readOnly={disabled}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Form.Check>
      )
      break
    case 'select':
      selectOptions = []
      if (field.options) {
        for (let i = 0; i < field.options.length; i++) {
          selectOptions.push({
            label: useSelectOptionLabel ? field.options[i].label : field.options[i].value,
            value: field.options[i].value,
          })
        }
      }

      input = (
        <InputFieldSet label={inputLabel}>
          <Select
            menuPortalTarget={document.body}
            placeholder={''}
            name={field.name}
            options={selectOptions}
            value={
              selectOptions
                ? selectOptions.find((option: any) => option.value === value)
                : undefined
            }
            onChange={(selectedOption) => {
              setFieldValue(field.name, selectedOption ? selectedOption.value : undefined)
              if (onChange && useOnChange) {
                onChange(selectedOption, field.name)
              }
            }}
            className='fieldset-input'
            styles={customStyles}
          />
        </InputFieldSet>
      )
      break
    case 'email':
      input = (
        <InputFieldSet label={inputLabel}>
          <input
            placeholder=''
            type='text'
            className={'form-control fieldset-input ' + customClassName}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            value={value ? value : ''}
            name={field.name ? field.name : undefined}
          />
        </InputFieldSet>
      )
      break
    case 'person':
      input = (
        <InputFieldSet label={inputLabel}>
          <SelectAsync
            menuPortalTarget={document.body}
            placeholder={''}
            name={field.name ? field.name : undefined}
            loadOptions={selectLoadOptions}
            value={value}
            cacheOptions
            defaultOptions
            isClearable
            isDisabled={disabled}
            onChange={(selectedOption) =>
              setFieldValue(field.name, selectedOption ? selectedOption : undefined)
            }
            className='fieldset-input'
            styles={customStyles}
          />
        </InputFieldSet>
      )
      break
    case 'connection':
      switch (field.name) {
        case 'labelIds':
          input = (
            <InputFieldSet label={inputLabel ? inputLabel : 'Etiquetas'}>
              <SelectAsync
                menuPortalTarget={document.body}
                placeholder={''}
                name={field.name}
                loadOptions={selectLoadOptions}
                value={value}
                isDisabled={disabled}
                cacheOptions
                defaultOptions
                isMulti
                onChange={(selectedOption) =>
                  setFieldValue(field.name, selectedOption ? selectedOption : undefined)
                }
                className='fieldset-input'
                styles={customStyles}
              />
            </InputFieldSet>
          )
          break
        case 'businessSegmentId':
          input = (
            <InputFieldSet label={inputLabel ? inputLabel : 'Segmento de Negócios'}>
              <Select
                menuPortalTarget={document.body}
                placeholder={''}
                name={field.name}
                options={selectOptions}
                value={
                  selectOptions
                    ? selectOptions.find((option: any) => option.value === value)
                    : undefined
                }
                onChange={(selectedOption) =>
                  setFieldValue(field.name, selectedOption ? selectedOption.value : undefined)
                }
                className='fieldset-input'
                styles={customStyles}
              />
            </InputFieldSet>
          )
          break
        case 'responsibleId':
          input = (
            <InputFieldSet label={inputLabel ? inputLabel : 'Responsável'}>
              <SelectAsync
                menuPortalTarget={document.body}
                placeholder={''}
                name={field.name}
                loadOptions={selectLoadOptions}
                value={value}
                cacheOptions
                defaultOptions
                isDisabled={disabled}
                onChange={(selectedOption) =>
                  setFieldValue(field.name, selectedOption ? selectedOption : undefined)
                }
                className='fieldset-input'
                styles={customStyles}
              />
            </InputFieldSet>
          )
          break
        case 'companyId':
          if (renderMode == 'editor') {
            input = (
              <InputFieldSet label={inputLabel ? inputLabel : 'Empresa'}>
                <SelectAsync
                  menuPortalTarget={document.body}
                  placeholder={''}
                  name={field.name}
                  loadOptions={selectLoadOptions}
                  value={value}
                  cacheOptions
                  isClearable
                  isDisabled={disabled}
                  defaultOptions
                  onChange={(selectedOption) => {
                    setFieldValue(field.name, selectedOption ? selectedOption : null)
                  }}
                  className='fieldset-input'
                  styles={customStyles}
                />
              </InputFieldSet>
            )
          } else {
            input = (
              <CompanyField label={inputLabel} field={field} value={value} disabled={disabled} />
            )
          }
          break
        case 'contactId':
          if (renderMode == 'editor') {
            input = (
              <InputFieldSet label={inputLabel ? inputLabel : 'Contato'}>
                <SelectAsync
                  menuPortalTarget={document.body}
                  placeholder={''}
                  name={field.name}
                  loadOptions={selectLoadOptions}
                  value={value}
                  cacheOptions
                  isDisabled={disabled}
                  isClearable
                  defaultOptions
                  onChange={(selectedOption) =>
                    setFieldValue(field.name, selectedOption ? selectedOption : undefined)
                  }
                  className='fieldset-input'
                  styles={customStyles}
                />
              </InputFieldSet>
            )
          } else {
            input = (
              <ContactField label={inputLabel} field={field} value={value} disabled={disabled} />
            )
          }
          break
        case 'ticketCategoryId':
          input = (
            <InputFieldSet label={inputLabel ? inputLabel : 'Categoria de Ticket'}>
              <SelectAsync
                menuPortalTarget={document.body}
                placeholder={''}
                name={field.name}
                loadOptions={selectLoadOptions}
                value={value}
                cacheOptions
                isClearable
                defaultOptions
                onChange={(selectedOption) =>
                  setFieldValue(field.name, selectedOption ? selectedOption : undefined)
                }
                className='fieldset-input'
                styles={customStyles}
              />
            </InputFieldSet>
          )
          break
        case 'funnelId':
          input = (
            <InputFieldSet label={inputLabel}>
              <SelectAsync
                menuPortalTarget={document.body}
                placeholder={''}
                name={field.name}
                loadOptions={selectLoadOptions}
                value={value}
                cacheOptions
                defaultOptions
                isDisabled={disabled}
                onChange={(selectedOption) => {
                  if (selectedOption && selectedOption.value != value?.value) {
                    setFieldValue(field.name, selectedOption ? selectedOption : undefined)
                  }
                  setFieldValue('funnelStepId', null)
                }}
                className='fieldset-input'
                styles={customStyles}
              />
            </InputFieldSet>
          )
          break
        case 'funnelStepId':
          if (renderMode == 'editor') {
            input = (
              <InputFieldSet label={inputLabel}>
                <Select
                  menuPortalTarget={document.body}
                  placeholder={''}
                  name={field.name}
                  isDisabled={disabled}
                  className='fieldset-input'
                  styles={customStyles}
                />
              </InputFieldSet>
            )
          } else {
            input = (
              <FunnelStepField label={inputLabel} field={field} value={value} disabled={disabled} />
            )
          }
          break
        case 'phones':
          if (!value) {
            input = (
              <a href='#' className=''>
                <span className='indicator-label'>+ Adicionar Telefone</span>
              </a>
            )
          } else {
            input = (
              <FieldArray
                name='phones'
                render={(arrayHelpers) => (
                  <div>
                    {value && Array.isArray(value) && value.length > 0 ? (
                      value.map((valueE: any, subIndex: any) => (
                        <div
                          key={subIndex}
                          className=''
                          style={{
                            display: 'flex',
                            flexFlow: 'wrap',
                            flexDirection: 'row',
                            marginBottom: '17px',
                          }}
                        >
                          <div style={{width: '85%'}}>
                            {' '}
                            <InputFieldSet label={inputLabel}>
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                {' '}
                                <Dropdown>
                                  <CustomDropdownToggle
                                    variant='outline-secondary'
                                    id='dropdown-custom'
                                  >
                                    <img
                                      src={loadDdiPhonesImages(`phones[${subIndex}]`, valueE)?.img}
                                      alt=''
                                      style={{width: '25px', height: '16px'}}
                                    />
                                    {/* <span style={{marginLeft: '5px'}}>
                                +{loadDdiPhoneImage()?.ddi}
                              </span> */}
                                  </CustomDropdownToggle>
                                  <CustomDropdownMenu>
                                    {JsonFlags.map((item, index) => (
                                      <div key={item?.pais + 'row2'}>
                                        <Dropdown.Item
                                          href='#'
                                          onClick={() => {
                                            let newValue = '+' + item?.ddi?.toString() + ' '
                                            setFieldValue(`phones[${subIndex}]`, newValue)
                                          }}
                                        >
                                          {' '}
                                          <img
                                            src={item?.img}
                                            style={{width: '25px', height: '16px'}}
                                          />{' '}
                                          <span style={{marginLeft: '10px'}}>{item.pais}</span>{' '}
                                          <span style={{marginLeft: '5px'}}>+{item.ddi}</span>
                                        </Dropdown.Item>
                                      </div>
                                    ))}
                                  </CustomDropdownMenu>
                                </Dropdown>
                                <div style={{width: '82%'}}>
                                  {loadDdiPhonesImages(`phones[${subIndex}]`, valueE)?.ddi ==
                                  '55' ? (
                                    <Field name={`phones[${subIndex}]`} component={PhoneField} />
                                  ) : (
                                    <Field name={`phones[${subIndex}]`} component={PhoneFieldNT} />
                                    // <div>
                                    //   {' '}
                                    //   <input
                                    //     placeholder=''
                                    //     type='text'
                                    //     className={'form-control fieldset-input ' + customClassName}
                                    //     disabled={disabled}
                                    //     onChange={onChange}
                                    //     onBlur={onChange}
                                    //     maxLength={30}
                                    //     name={`phones.${subIndex}`}
                                    //     value={valueE ? valueE : ''}
                                    //   />
                                    // </div>
                                  )}
                                </div>
                              </div>
                            </InputFieldSet>
                          </div>
                          {/* <InputFieldSet label={'Telefone'} style={{width: '100%'}}>
                            <Field name={`phones.${subIndex}`} component={PhoneField} /> */}
                          {/* <Field
                              name={`phones.${subIndex}`}
                              className={'form-control fieldset-input'}
                              style={{paddingRight: '3.5rem'}}
                            /> */}
                          {/* </InputFieldSet> */}
                          <div style={{marginLeft: 'auto'}}>
                            {' '}
                            <button
                              type='button'
                              className='btn btn-sm btn-danger'
                              style={{marginTop: '0.4rem', marginLeft: '1rem'}}
                              onClick={() => arrayHelpers.remove(subIndex)} // remove a friend from the list
                            >
                              <span className='indicator-label'>-</span>
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <a
                        href='#'
                        className=''
                        onClick={() => arrayHelpers.push('')} // insert an empty string at a position
                      >
                        <span className='indicator-label'>+ Adicionar Telefone</span>
                      </a>
                    )}

                    {value && Array.isArray(value) && value.length > 0 && (
                      <a
                        href='#'
                        className=''
                        onClick={() => arrayHelpers.push('')} // insert an empty string at a position
                      >
                        <span className='indicator-label'>+ Adicionar Telefone</span>
                      </a>
                    )}
                  </div>
                )}
              />
            )
          }
          break
        case 'emails':
          if (!value) {
            input = (
              <a href='#' className=''>
                <span className='indicator-label'>+ Adicionar E-mail</span>
              </a>
            )
          } else {
            input = (
              <FieldArray
                name='emails'
                render={(arrayHelpers) => (
                  <div>
                    {value && Array.isArray(value) && value.length > 0 ? (
                      value.map((valueE: any, subIndex: any) => (
                        <div key={subIndex} className='fv-row mb-4 d-flex align-items-center'>
                          <InputFieldSet label={'E-mail'} style={{width: '100%'}}>
                            <Field
                              name={`emails.${subIndex}`}
                              className={'form-control fieldset-input'}
                              style={{paddingRight: '3.5rem'}}
                            />
                          </InputFieldSet>
                          <button
                            type='button'
                            className='btn btn-sm btn-danger'
                            style={{marginTop: '0.4rem', marginLeft: '1rem'}}
                            onClick={() => arrayHelpers.remove(subIndex)} // remove a friend from the list
                          >
                            <span className='indicator-label'>-</span>
                          </button>
                        </div>
                      ))
                    ) : (
                      <a
                        href='#'
                        className=''
                        onClick={() => arrayHelpers.push('')} // insert an empty string at a position
                      >
                        <span className='indicator-label'>+ Adicionar E-mail</span>
                      </a>
                    )}

                    {value && Array.isArray(value) && value.length > 0 && (
                      <a
                        href='#'
                        className=''
                        onClick={() => arrayHelpers.push('')} // insert an empty string at a position
                      >
                        <span className='indicator-label'>+ Adicionar E-mail</span>
                      </a>
                    )}
                  </div>
                )}
              />
            )
          }
          break
        default:
          input = (
            <InputFieldSet label={inputLabel}>
              <input
                placeholder=''
                type='text'
                className={'form-control fieldset-input ' + customClassName}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                value={value ? value : ''}
                name={field.name ? field.name : undefined}
              />
            </InputFieldSet>
          )
          break
      }
      break
  }

  return input
}

export default FieldTypeInput
