/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon, toAbsoluteUrl, WithChildren} from '../../helpers'

type Props = {
  label?: any
  style?: any
  styleLegend?: any
}

const InputFieldSet: FC<Props & WithChildren> = ({children, label, style ={}, styleLegend={}}) => {
  return (
    <fieldset className="border fieldset-group" style={style}>
      <legend style={styleLegend}  className="fieldset-legend">{label}</legend>
      {children}
    </fieldset>
    
  )
}

export {InputFieldSet}
