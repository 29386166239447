import {FC} from 'react'

type Props = {
  triggerId?: number
}

/**
 * 
 * @param param0 {
        "id": 1,
        "key": "CARD_ENTER_STEP",
        "entity": "CARD",
        "name": "Quando entrar na etapa"
    },
    {
        "id": 2,
        "key": "CARD_LEAVE_STEP",
        "entity": "CARD",
        "name": "Quando sair de uma etapa"
    },
    {
        "id": 3,
        "key": "CARD_MARKED_AS_WIN",
        "entity": "CARD",
        "name": "Oportunidade marcada como ganha"
    },
    {
        "id": 4,
        "key": "CARD_MARKED_AS_LOST",
        "entity": "CARD",
        "name": "Oportunidade marcada como perda"
    },
    {
        "id": 5,
        "key": "CARD_MARKED_AS_OPEN",
        "entity": "CARD",
        "name": "Oportunidade marcada como aberta"
    },
    {
        "id": 6,
        "key": "CARD_MARKED_AS_FREEZE",
        "entity": "CARD",
        "name": "Oportunidade marcada como congelada"
    },
    {
        "id": 7,
        "key": "CARD_MARKED_AS_SOLVED",
        "entity": "CARD",
        "name": "Ticket marcado como resolvido"
    },
    {
        "id": 8,
        "key": "CARD_MARKED_AS_UNSOLVED",
        "entity": "CARD",
        "name": "Ticket marcado como não resolvido"
    },
    {
        "id": 9,
        "key": "CARD_MARKED_AS_ARCHIVED",
        "entity": "CARD",
        "name": "Ticket marcado como arquivado"
    }
 * @returns 
 */

const TriggerIdCell: FC<Props> = ({triggerId}) => (
  <>
    {' '}
    {triggerId === 1 ? (
      <>Quando entrar na etapa</>
    ) : triggerId === 2 ? (
      <>Quando sair de uma etapa</>
    ) : triggerId === 3 ? (
      <>Oportunidade marcada como ganha</>
    ) : triggerId === 4 ? (
      <>Oportunidade marcada como perda</>
    ) : triggerId === 5 ? (
      <>Oportunidade marcada como aberta</>
    ) : triggerId === 6 ? (
      <>
        Oportunidade marcada como congelada
      </>
    ) : triggerId === 7 ? (
      <>Ticket marcado como resolvido</>
    ) : triggerId === 8 ? (
      <>
        Ticket marcado como não resolvido
      </>
    ) : triggerId === 9 ? (
      <>Ticket marcado como arquivado</>
    ) : triggerId === 10 ? (
      <>Ao criar contato</>
    ) : (
      <>Não identificado</>
    )}
  </>
)

export {TriggerIdCell}
