import {useListView} from '../../../../../modules/view/ListViewProvider'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {InputFieldSet} from '../../../../../../_metronic/partials/inputs/InputFieldSet'
import {Dropdown1} from '../../../../../../_metronic/partials'
import {AutomaticActionsFilter} from './AutomaticActionsFilter'
import {useAutomaticActionsFormModalDispatch} from '../../core/AutomaticActionsFormModalContext'
import {AutomaticActionsSearchComponent} from './AutomaticActionsSearchComponent'

const AutomaticActionsHeader = () => {
  const automaticActionFormModalDispatch = useAutomaticActionsFormModalDispatch()

  const openAddModal = () => {
    automaticActionFormModalDispatch({
      type: 'open-add-modal',
    })
  }

  return (
    <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
      <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid'>
        <div
          id='kt_page_title'
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className='page-title d-flex flex-wrap me-3 flex-row justify-content-center'
        >
          <i className='fa-solid fa-filter fs-2 me-1'></i>
          <h3>
            <strong>Ações Automáticas</strong>
          </h3>
        </div>

        <div className='d-flex align-items-center gap-2 gap-lg-3'>
          <AutomaticActionsSearchComponent />

          <AutomaticActionsFilter />

          <a
            href='#'
            onClick={openAddModal}
            className='btn btn-sm fw-bold btn-primary'
            style={{marginTop: 5}}
          >
            Adicionar
          </a>
        </div>
      </div>
    </div>
  )
}

export {AutomaticActionsHeader}
