import { FC, useEffect, useRef, useState } from "react"
import * as Yup from 'yup'
import { EMAIL_MESSAGES_STATUS, EmailMessages } from "../../core/_models"
import { Spinner } from "react-bootstrap"
import { KTCardBody } from "../../../../../_metronic/helpers"
import { ErrorMessage, Field, Form, Formik, FastField } from "formik"
import ReactQuill from "react-quill";
import { createEmailMessages, getEmailById, sendEmailMessages, updateEmailMessages } from "../../core/_requests"
import Swal from 'sweetalert2'
import { INTEGRATION_STATUS, IntegrationEmail } from "../../../settings/integration/core/_models"
import { getCustomerIntegrationEmailByCustomer, getUserIntegrationEmailByCustomer } from "../../../settings/integration/core/_requests"
import CreatableAsync from 'react-select/creatable'
import { components } from "react-select"
import { getContactById } from "../../../contacts/core/_requests"
import { InputFieldSet } from "../../../../../_metronic/partials/inputs/InputFieldSet"
import styled from "styled-components"
import { createAttachment, getListAttachments } from "../../../../../_metronic/helpers/attachament-component/core/_request"
import AttachmentCard from "../../../../../_metronic/helpers/attachament-component/Components/AttachmentCard/Index"
type Props = {
  card: any
  onClose: any,
  emailMessageProp: any
  contact: any
}

const spinnerStyle = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const spinnerTextStyle = {
  color: '#93a4ee',
  fontWeight: 'bold',
  padding: 50,
  margin: 50,
}
const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    height: '36px',
    minHeight: '36px',
    borderStyle: 'none',
    borderRadius: '6px',
    backgroundColor: undefined,
    paddingRight: '6px',
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '36px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px'
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '36px'
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none'
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: '3px',
    fontSize: '1rem'
  })
}
const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;


const CustomText = styled(FastField)`
  border: none; 
  background-color: transparent;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
   appearance: none;      
  -webkit-appearance: none;
  -moz-appearance: none;
   &:-webkit-autofill,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 5000s ease-in-out 0s !important;
    background-color: transparent !important;
    -webkit-text-fill-color: currentColor !important;
  }
  &:disabled {
    background-color: #f5f5f5;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 1.1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; 
`;


const ClearIndicator = (props: any) => {
  const { children = <components.ClearIndicator {...props} />, clearValue, innerProps } = props

  return (
    <div
      {...innerProps}
      onMouseDown={(e) => {
        e.stopPropagation()
        clearValue()
      }}
    >
      {children}
    </div>
  )
}
type EmailOption = {
  id: string;
  value: string;
  label: string;
};
const CardSendEmailForm: FC<Props> = ({ card, onClose, emailMessageProp, contact }) => {
  const [loading, setLoading] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingSend, setLoadingSend] = useState(false)
  const [emailOptions, setEmailOptions] = useState<EmailOption[]>([]);
  const [emailContactOptions, setEmailContactOptions] = useState<any[]>([]);
  const [emailMessages, setEmailMessages] = useState<EmailMessages | null>(null);
  const validationSchema = Yup.object({
    subject: Yup.string().required("Campo obrigatório"),
    to: Yup.string().required("Campo obrigatório"),
    content: Yup.string().required("Campo obrigatório"),
  });

  const getDataEmail = async () => {
    setLoading(true);
    try {
      const response: null | EmailMessages = await getEmailById(emailMessageProp?.id)
      if (response) {
        setEmailMessages(response);
      }
    } catch (error: any) {
      console.log(error);
      let text = 'Erro ao encontrar e-mail, tente novamente mais tarde...'
      if (error?.response?.data?.message) {
        text = error?.response?.data?.message
      }
      Swal.fire({
        title: 'Opss..',
        text: text,
        icon: 'error',
        confirmButtonText: 'OK',
      })
      onClose()
    }
    setLoading(false);
  };
  const getDataOptionsEmail = async (): Promise<EmailOption[]> => {
    setLoading(true);
    try {
      let userIntegrationEmail = null
      let customerIntegrationEmail = null
      try {
        userIntegrationEmail = await getUserIntegrationEmailByCustomer();
      } catch (error) {
        console.log(error)
      }
      try {
        customerIntegrationEmail = await getCustomerIntegrationEmailByCustomer();
      } catch (error) {
        console.log(error)
      }
      const options: EmailOption[] = [];
      if (emailMessageProp?.status == EMAIL_MESSAGES_STATUS.SENT) {
        if (userIntegrationEmail) {
          options.push({
            id: `user-${userIntegrationEmail.id}`,
            value: `${userIntegrationEmail.config.emailAddress}`,
            label: userIntegrationEmail.config.displayName || userIntegrationEmail.config.emailAddress,
          });
        }

        if (customerIntegrationEmail) {
          options.push({
            id: `customer-${customerIntegrationEmail.id}`,
            value: `${customerIntegrationEmail.config.emailAddress}`,
            label: customerIntegrationEmail.config.displayName || customerIntegrationEmail.config.emailAddress,
          });
        }
        if (options.length == 0) {
          options.push({
            id: `email-${emailMessageProp.id}`,
            value: `${emailMessageProp.from}`,
            label: `${emailMessageProp.from}`,
          });
        }
      } else {
        if (userIntegrationEmail && userIntegrationEmail.status == INTEGRATION_STATUS.ACTIVATED) {
          options.push({
            id: `user-${userIntegrationEmail.id}`,
            value: `${userIntegrationEmail.config.emailAddress}`,
            label: userIntegrationEmail.config.displayName ? `${userIntegrationEmail.config.displayName} <${userIntegrationEmail.config.emailAddress}>` : userIntegrationEmail.config.emailAddress,
          });
        }

        if (customerIntegrationEmail && customerIntegrationEmail.status == INTEGRATION_STATUS.ACTIVATED) {
          options.push({
            id: `customer-${customerIntegrationEmail.id}`,
            value: `${customerIntegrationEmail.config.emailAddress}`,
            label: customerIntegrationEmail.config.displayName ? `${customerIntegrationEmail.config.displayName} <${customerIntegrationEmail.config.emailAddress}>` : customerIntegrationEmail.config.emailAddress,
          });
        }
        if (options.length == 0) {
          throw new Error();
        }
      }

      try {
        let list: Array<any> = []
        if (contact) {
          let resps: any = await getContactById(contact)
          if (resps) {
            for (let i = 0; i < resps.emails?.length; i++) {
              list.push({ label: resps.emails[i]?.email, value: resps.emails[i]?.email })
            }
          }
          setEmailContactOptions(list)
        }
      } catch (error) {
        setEmailContactOptions([])
      }

      return options;
    } catch (error) {
      console.log(error)
      Swal.fire("Erro", "Opss.. É necessário configurar uma conta de e-mail para usar esta funcionalidade. Acesse o menu Configurações -> Integrações OU Minha Conta -> Configurações", "error");
      onClose();
      return [];
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    if (emailMessageProp && emailMessageProp.id) getDataEmail();
    getDataOptionsEmail()
      .then((options) => {
        setEmailOptions(options);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (emailMessages?.id) loadDataPage()
  }, [emailMessages]);

  const [attachmentsData, setAttachmentsData] = useState<any>({
    data: [],
    pagination: {},
  })
  const [storegeLoading, setStoregeLoading] = useState(false)
  const inputFileRef = useRef<any>(null)

  const loadDataPage = async (page = 1, sizePerPage = 5) => {
    try {
      let query = `emailMessageId=${emailMessages?.id}&page=${page}&perPage=${sizePerPage}`
      let result = await getListAttachments(query)
      let newData = { ...attachmentsData }
      if (page > 1) {
        newData.data = newData?.data?.concat(result?.data)
      } else {
        newData.data = result?.data
      }
      newData.pagination = result.pagination
      setAttachmentsData(newData)
    } catch (e) {
      console.log(e)
    }
  }

  const handleStoreFile = async (file: any, values: any) => {
    if(attachmentsData && attachmentsData.data && attachmentsData.data.length == 5){
      Swal.fire("Erro", "Opss.. Limite de anexos é 5.", "info");
    }else{
      setStoregeLoading(true)
      let result = null
      try {
        if (!values.id) {
          result = await createEmailMessages({
            cardId: card.id,
            content: values.content,
            from: values.from ? values.from : emailOptions[0]?.value,
            subject: values.subject,
            to: values.to,
          });
          if (result) {
            setEmailMessages(result);
          }
        } else {
          await updateEmailMessages({
            id: values.id,
            cardId: card.id,
            to: values.to,
            from: values.from,
            subject: values.subject,
            content: values.content,
          });
        }
        if (!result) {
          result = values
        }
        const formData = new FormData()
        formData.append("emailMessageId", result.id)
        formData.append('file', file)
        let response = await createAttachment(formData)
        addOnState(response?.url)
        setStoregeLoading(false)
      } catch (e) {
        console.log(e)
        setStoregeLoading(false)
        Swal.fire("Erro", "Opss.. Erro ao enviar o anexo", "error");
      }
    }
  }
  const addOnState = (obj: any) => {
    let newData = { ...attachmentsData }
    newData?.data?.push(obj)
    setAttachmentsData(newData)
  }
  const createEmail = async (from: string, to: string, subject: string, content: string) => {
    setLoadingForm(true);
    try {
      const response: null | EmailMessages = await createEmailMessages({
        cardId: card.id,
        content,
        from: from ? from : emailOptions[0]?.value,
        subject,
        to,
      });
      if (response) {
        setEmailMessages(response);
      }
    } catch (error: any) {
      console.error(error);
      let text = 'Erro ao criar e-mail. Tente novamente mais tarde.';
      if (error?.response?.data?.message) {
        text = error.response.data.message;
      }
      Swal.fire({ title: 'Erro', text, icon: 'error', confirmButtonText: 'OK' });
      onClose();
    } finally {
      setLoadingForm(false);
    }
  };

  const updateEmail = async (id: number, from: string, to: string, subject: string, content: string) => {
    setLoadingForm(true);
    try {
      await updateEmailMessages({
        id: id,
        cardId: card.id,
        to: to,
        from: from,
        subject: subject,
        content: content,
      });

    } catch (error: any) {
      console.error(error);
      let text = 'Erro ao atualizar e-mail. Tente novamente mais tarde.';
      if (error?.response?.data?.message) {
        text = error.response.data.message;
      }
      Swal.fire({ title: 'Erro', text, icon: 'error', confirmButtonText: 'OK' });
      onClose();
    } finally {
      setLoadingForm(false);
    }
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    if (subjectTypingTimeoutRef.current) {
      clearTimeout(subjectTypingTimeoutRef.current);
    }
    if (fromTypingTimeoutRef.current) {
      clearTimeout(fromTypingTimeoutRef.current);
    }
    if (toTypingTimeoutRef.current) {
      clearTimeout(toTypingTimeoutRef.current);
    }

    setLoadingForm(true);
    setLoadingSend(true);
    try {
      if (!values.id) {
        const result = await createEmailMessages({
          cardId: card.id,
          content: values.content,
          from: values.from ? values.from : emailOptions[0]?.value,
          subject: values.subject,
          to: values.to,
        });
        const resultSendEmail = await sendEmailMessages(result.id)
        if (resultSendEmail.status == EMAIL_MESSAGES_STATUS.FAILURE) {
          Swal.fire('Erro ao enviar e-mail', '', 'error')
        } else {
          Swal.fire('E-mail enviado', '', 'success')
        }
        onClose()
      } else {
        await updateEmailMessages({
          id: values.id,
          cardId: card.id,
          to: values.to,
          from: values.from,
          subject: values.subject,
          content: values.content,
        });
        const resultSendEmail = await sendEmailMessages(emailMessages!.id)
        if (resultSendEmail.status == EMAIL_MESSAGES_STATUS.FAILURE) {
          Swal.fire('Erro ao enviar e-mail', '', 'error')
        } else {
          Swal.fire('E-mail enviado', '', 'success')
        }
        onClose()
      }
    } catch (error: any) {
      let text = 'Erro relacionado ao e-mail, tente novamente mais tarde...'
      if (error?.response?.data?.message) {
        text = error?.response?.data?.message
      }
      Swal.fire({
        title: 'Opss..',
        text: text,
        icon: 'error',
        confirmButtonText: 'OK',
      })
    } finally {
      setLoadingForm(false);
      setLoadingSend(false);
      setSubmitting(false);
    }
  };
  const typingTimeoutRef = useRef<any>(null)
  const subjectTypingTimeoutRef = useRef<any>(null)
  const fromTypingTimeoutRef = useRef<any>(null)
  const toTypingTimeoutRef = useRef<any>(null)
  return (
    loading ? (
      <div style={spinnerStyle}>
        <Spinner style={spinnerTextStyle} />
      </div>
    ) : (
      <KTCardBody className="p-4">
        <Formik
          enableReinitialize
          initialValues={{
            id: emailMessages?.id || "",
            from: emailMessages?.from || "",
            to: emailMessages?.to ? emailMessages?.to : emailContactOptions.length > 0 ? emailContactOptions[0]?.value : "",
            subject: emailMessages?.subject || "",
            content: emailMessages?.content || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            const handleQuillChange = (content: any) => {
              setFieldValue("content", content);
              if (typingTimeoutRef.current) {
                clearTimeout(typingTimeoutRef.current);
              }
              typingTimeoutRef.current = setTimeout(async () => {
                if (values?.id) {
                  await updateEmail(
                    values.id as number,
                    values.from,
                    values.to,
                    values.subject,
                    content
                  )
                } else {
                  await createEmail(values.from, values.to, values.subject, content)
                }
              }, 1000);
            };

            const handleSubjectChange = (e: any) => {
              if (emailMessages?.status == EMAIL_MESSAGES_STATUS.SENT) return;
              const value = e.target.value;
              setFieldValue("subject", value);
              if (!values?.id) return;
              if (subjectTypingTimeoutRef.current) {
                clearTimeout(subjectTypingTimeoutRef.current);
              }
              subjectTypingTimeoutRef.current = setTimeout(async () => {
                await updateEmail(
                  values.id as number,
                  values.from,
                  values.to,
                  value,
                  values.content
                )
              }, 1000);
            };

            const handleFromChange = (e: any) => {
              if (emailMessages?.status == EMAIL_MESSAGES_STATUS.SENT) return;
              const value = e.target.value;
              setFieldValue("from", value);
              if (!values?.id) return;
              if (fromTypingTimeoutRef.current) {
                clearTimeout(fromTypingTimeoutRef.current);
              }
              fromTypingTimeoutRef.current = setTimeout(async () => {
                await updateEmail(
                  values.id as number,
                  value,
                  values.to,
                  values.subject,
                  values.content
                )
              }, 1000);
            };

            const handleToChange = (e: any) => {
              if (emailMessages?.status == EMAIL_MESSAGES_STATUS.SENT) return;
              const value = e.value;
              setFieldValue("to", value);
              if (!values?.id) return;
              if (toTypingTimeoutRef.current) {
                clearTimeout(toTypingTimeoutRef.current);
              }
              toTypingTimeoutRef.current = setTimeout(async () => {
                await updateEmail(
                  values.id as number,
                  values.from,
                  value,
                  values.subject,
                  values.content
                )
              }, 1000);
            };

            return (
              <Form style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <div style={{ display: "flex", gap: "15px" }}>
                  <div style={{ flex: 1 }}>
                    <InputFieldSet styleLegend={{
                      marginBottom: '-4px',
                    }} label={'De*'}>
                      <SelectContainer>
                        <Field style={{
                          border: 'none',
                          appearance: 'none',
                          padding: '0.5rem 2.5rem 0.5rem 0.5rem'
                        }}
                          as="select"
                          disabled={
                            isSubmitting ||
                            loading ||
                            loadingSend ||
                            loadingForm ||
                            storegeLoading ||
                            emailMessages?.status === EMAIL_MESSAGES_STATUS.SENT
                          }
                          name="from"
                          onChange={handleFromChange}
                          className="form-control"
                        >
                          {emailOptions?.map((option: EmailOption) => (
                            <option key={option.id} value={option.value}>
                              {option.label}
                            </option>
                          ))}

                        </Field>
                        <IconWrapper>
                          <i style={{color:'#cccccc', fontSize:'1rem'}} className="fa fa-chevron-down" />
                        </IconWrapper>
                      </SelectContainer>
                    </InputFieldSet>
                    <ErrorMessage name="from" component="div" className="text-danger" />
                  </div>
                </div>
                <div style={{ display: "flex", gap: "15px" }}>
                  <div style={{ flex: 1 }}>
                    <InputFieldSet styleLegend={{
                      marginBottom: '-4px',
                    }} label={'Para*'}>
                      <CreatableAsync
                        placeholder={'Para*'}
                        name='to'
                        isDisabled={
                          isSubmitting ||
                          loading ||
                          loadingSend ||
                          loadingForm ||
                          storegeLoading ||
                          emailMessages?.status === EMAIL_MESSAGES_STATUS.SENT
                        }
                        value={
                          emailContactOptions.find((option) => option.value == values.to) || { label: values.to, value: values.to }
                        }
                        components={{ ClearIndicator }}
                        formatCreateLabel={(inputValue) => `Enviar para "${inputValue}"`}
                        noOptionsMessage={() => 'Sem registros...Digite para buscar'}
                        options={emailContactOptions}
                        styles={customStyles}
                        onChange={handleToChange}
                      />
                    </InputFieldSet>
                    <ErrorMessage name="to" component="div" className="text-danger" />
                  </div>
                </div>

                <div style={{ display: "flex", gap: "15px" }}>
                  <div style={{ flex: 1 }}>
                    <InputFieldSet styleLegend={{
                      marginBottom: '-4px',
                    }} label={'Assunto*'}>
                      <CustomText 
                        disabled={isSubmitting || loading || loadingForm ||
                          storegeLoading ||  loadingSend || emailMessages?.status == EMAIL_MESSAGES_STATUS.SENT}
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Assunto*"
                        onChange={handleSubjectChange}
                      />
                    </InputFieldSet>
                    <ErrorMessage name="subject" component="div" className="text-danger" />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '80%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <FastField disabled={
                            isSubmitting ||
                            loading ||
                            loadingSend ||
                            loadingForm ||
                            storegeLoading ||
                            emailMessages?.status === EMAIL_MESSAGES_STATUS.SENT
                          } name="content">
                        {() => (
                          <ReactQuill
                            className="custom-quill"
                            theme="snow"
                            value={values.content}
                            onChange={(content) => {
                              if (emailMessages?.status == EMAIL_MESSAGES_STATUS.SENT) return;
                              handleQuillChange(content)
                            }}
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, false] }],
                                ["bold", "italic", "underline", "strike"],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [{ align: [] }],
                                [{ font: [] }],
                                [{ size: [] }],
                                // ['image'],
                              ],
                            }}
                            readOnly={isSubmitting || loadingSend || loading || loadingForm || emailMessages?.status == EMAIL_MESSAGES_STATUS.SENT}
                            placeholder="Mensagem*"
                          />
                        )}
                      </FastField>
                      <ErrorMessage name="content" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div
                    style={{
                      width: '20%',
                      border: '1px dashed gray',
                      padding: '10px',
                      marginLeft: '10px',
                      textAlign: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      if (inputFileRef?.current && !storegeLoading) {
                        if (isSubmitting || loading || loadingForm || loadingSend ||
                          storegeLoading || emailMessages?.status === EMAIL_MESSAGES_STATUS.SENT) return
                        inputFileRef.current.click();
                      }
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                        if (isSubmitting || loading || loadingForm || loadingSend ||
                          storegeLoading || emailMessages?.status === EMAIL_MESSAGES_STATUS.SENT) return
                        handleStoreFile(e.dataTransfer.files[0], values);
                      }
                    }}
                  >
                    <h3 className='mt-3 label-card' style={{marginBottom:0}}>Anexos</h3>
                    <p className='label-card'style={{ fontSize:10}}>Máx. 20MB</p>
                    {storegeLoading ? (
                      <>
                        Enviando solicitação... <Spinner size='sm' style={{ fontWeight: 'bold' }} />
                      </>
                    ) : attachmentsData?.data?.length <= 0 ? (
                      <>
                        <div className='label-card'>Sem anexos até o momento.</div>
                        <br />
                      </>
                    ) : (
                      attachmentsData.data.map((item: any, index: any) => (
                        <AttachmentCard
                          key={'row' + index}
                          attachmentId={item?.id}
                          FileNameData={item?.fileName}
                          loadDataPage={loadDataPage}
                          attachmentsData={attachmentsData}
                          setAttachmentsData={setAttachmentsData}
                          emailMessage={emailMessages}
                          newLayout={true}
                        />
                      ))
                    )}

                    <input
                      type='file'
                      onChange={(e: any) => {
                        handleStoreFile(e?.target?.files[0], values);
                      }}
                      style={{ display: 'none' }}
                      ref={inputFileRef}
                    />

                    {attachmentsData?.pagination?.page < attachmentsData?.pagination?.pagesCount && (
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <button
                          className='btn btn-sm btn-wl-custom-primary-collor'
                          onClick={(e) => {
                            e.stopPropagation();
                            const newPage = attachmentsData?.pagination?.page + 1;
                            loadDataPage(newPage);
                          }}
                        >
                          Carregar Mais Anexos
                        </button>
                      </div>
                    )}
                  </div>


                </div>
                <div style={{ justifyContent: "flex-end", alignItems: "center", display: "flex", flexDirection: "row" }}>
                  {emailMessages?.status == EMAIL_MESSAGES_STATUS.SENT ? (
                    <></>
                  ) : (
                    <button
                      type="submit"
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        fontSize: "16px",
                        borderRadius: "8px",
                      }}
                      className="btn btn-sm fw-bold btn-wl-custom-primary-collor"
                      disabled={isSubmitting || loading || loadingForm || loadingSend ||
                        storegeLoading || emailMessages?.status == EMAIL_MESSAGES_STATUS.SENT}
                    >
                      {loadingSend ? <Spinner size="sm" /> : "Enviar"}
                    </button>
                  )}
                </div>
                {emailMessages?.error && (
                  <div style={{ marginTop: '20px' }}>
                    <h3 style={{ color: '#FF0000' }}>
                      <strong>Erro no envio</strong>
                    </h3>
                    <p style={{ color: '#FF0000' }}>{emailMessages.error}</p>
                  </div>
                )}

              </Form>
            )
          }}
        </Formik>
      </KTCardBody>
    )
  );
}

export { CardSendEmailForm }