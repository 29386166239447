import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {FunnelWrapper} from '../pages/funnel/FunnelWrapper'
import {Companies} from '../pages/companies/Companies'
import {Contacts} from '../pages/contacts/Contacts'
import {Opportunities} from '../pages/cards/Opportunities'
import {Tickets} from '../pages/cards/Tickets'
import {Tasks} from '../pages/tasks/Tasks'
import {FunnelSettings} from '../pages/settings/funnels/FunnelSettings'
import {LabelSettings} from '../pages/settings/labels/LabelSettings'
import {TerminationReasonsSettings} from '../pages/settings/termination-reasons/TerminationReasonsSettings'
import {CustomFields} from '../pages/settings/custom-fields/CustomFields'
import {CustomForms} from '../pages/settings/custom-forms/CustomForms'
import {TicketCategories} from '../pages/settings/ticket-categories/TicketCategories'
import {Import} from '../pages/settings/import/Import'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {CARD_TYPES} from '../pages/cards/core/_models'
import {useAuth} from '../modules/auth'
import {Users} from '../pages/settings/users/Users'
import {Teams} from '../pages/settings/teams/Teams'
import {AutomaticActions} from '../pages/settings/automatic-actions/AutomaticActions'
import { Integration } from '../pages/settings/integration/Integration'
const PrivateRoutes = () => {
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='dashboard/opportunity-analysis' element={<DashboardWrapper />} />
        <Route path='dashboard/ticket-analysis' element={<DashboardWrapper />} />
        <Route path='funnel/*' element={<FunnelWrapper />} />
        {/*<Route path='opportunities' element={<Opportunities />} /> */}
        <Route path='opportunities/*' element={<Opportunities />} />
        <Route path='tickets/*' element={<Tickets />} />
        <Route path='companies/*' element={<Companies />} />
        <Route path='contacts/*' element={<Contacts />} />
        <Route path='tasks/*' element={<Tasks />} />
        {currentUser?.role?.permissions?.find((e: any) =>
          e?.key?.includes('manage_my_team_users')
        ) ||
        currentUser?.role?.permissions?.find((e: any) => e?.key?.includes('manage_all_users')) ? (
          <Route path='users/*' element={<Users />} />
        ) : null}
        {currentUser?.role?.permissions?.find((e: any) => e?.key?.includes('manage_all_users')) ? (
          <Route path='teams/*' element={<Teams />} />
        ) : null}
        {currentUser?.role?.key === 'admin' && (
          <>
            <Route path='settings/funnels' element={<FunnelSettings />} />
            <Route path='settings/termination-reasons' element={<TerminationReasonsSettings />} />
            <Route path='settings/labels' element={<LabelSettings />} />
            <Route path='settings/custom-fields' element={<CustomFields />} />
            <Route path='settings/forms' element={<CustomForms />} />
            <Route path='settings/ticket-categories' element={<TicketCategories />} />
            <Route path='settings/import' element={<Import />} />
            <Route path='settings/automatic-actions' element={<AutomaticActions />} />
            <Route path='settings/integrations' element={<Integration />} />
          </>
        )}
        <Route path='my-profile/config' element={<Integration userConfig={true} />} />

        {/* Lazy Modules */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
