import {AxiosResponse} from 'axios'
import {
  ActionConfig,
  AutomaticAction,
  AutomaticActionsQueryResponse,
  TriggerConfig,
} from './_models'
import {request} from '../../../../services/crm-api/request'

const getAutomaticActions = async (query: string): Promise<AutomaticActionsQueryResponse> => {
  return request(
    {
      method: 'GET',
      url: `/automatic-actions?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<AutomaticActionsQueryResponse>) => d.data)
}

const getAutomaticActionsById = async (id: any): Promise<AutomaticAction> => {
  return request(
    {
      method: 'GET',
      url: `/automatic-actions-config/${id}`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<AutomaticAction>) => d.data)
}

const getAutomaticActionsConfig = async (query: string): Promise<AutomaticActionsQueryResponse> => {
  return request(
    {
      method: 'GET',
      url: `/automatic-actions-config?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<AutomaticActionsQueryResponse>) => d.data)
}

const postAutomaticActionsConfig = async (data: any): Promise<any> => {
  return request(
    {
      method: 'POST',
      url: `/automatic-actions-config`,
      data,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<any>) => d.data)
}

const updateAutomaticActionsConfig = async (id: any, data: any): Promise<any> => {
  return request(
    {
      method: 'PATCH',
      url: `/automatic-actions-config/${id}`,
      data,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<any>) => d.data)
}

const changeAutomaticActionStatusRequest = async (id: any, status: number): Promise<any> => {
  return request(
    {
      method: 'PATCH',
      url: `/automatic-actions-config/change-status/${id}`,
      data: {
        status,
      },
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<any>) => d.data)
}

const getAutomaticActionsConfigActions = async (entity?:string): Promise<any[]> => {
  return request(
    {
      method: 'GET',
      url: `/automatic-actions-config/actions`,
      params: entity? {entity} : undefined
    },
    {
      
      setAuth: true,
    }
  ).then((d: AxiosResponse<any[]>) => d.data)
}

const getAutomaticActionsConfigTriggers = async (
  entity?: string,
  entityType?: number,
  funnelId?: number
): Promise<any[]> => {
  return request(
    {
      method: 'GET',
      url: `/automatic-actions-config/triggers`,
      params: {
        entity,
        entityType,
        funnelId,
      },
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<any[]>) => d.data)
}

const getAutomaticActionsConfigStatus = async (funnelId: any): Promise<any[]> => {
  return request(
    {
      method: 'GET',
      url: `/automatic-actions-config/card-status`,
      params: {
        funnelId,
      },
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<any[]>) => d.data)
}

const deleteAutomaticActionsConfig = async (id: any): Promise<any> => {
  return request(
    {
      method: 'DELETE',
      url: `/automatic-actions-config/${id}`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<any>) => d.data)
}

export {
  getAutomaticActions,
  getAutomaticActionsById,
  getAutomaticActionsConfig,
  getAutomaticActionsConfigActions,
  getAutomaticActionsConfigTriggers,
  postAutomaticActionsConfig,
  updateAutomaticActionsConfig,
  changeAutomaticActionStatusRequest,
  getAutomaticActionsConfigStatus,
  deleteAutomaticActionsConfig,
}
