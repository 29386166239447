import { EmailMessageTable } from './table/EmailMessage'


const EmailMessageComponent = ({ entityData, entity }: any) => {
  return (
    <>
        <EmailMessageTable entityData={entityData} entity={entity} />
    </>
  )
}

export { EmailMessageComponent }
