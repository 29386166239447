import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {FunnelStage} from '../core/_models'
import clsx from 'clsx'
import {ListLoading} from '../../../modules/view/ListLoading'
import {createFunnelStage, updateFunnelStage} from '../core/_requests'
import {InputFieldSet} from '../../../../_metronic/partials/inputs/InputFieldSet'
import Swal from 'sweetalert2'
import {useFunnelDispatch} from '../core/FunnelContext'

type Props = {
  isLoading: boolean
  funnelStage: any
  onCloseModal: any
  funnel: any
}

const editDataSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Mínimo 3 Caracteres')
    .max(50, 'Máximo 50 Caracteres')
    .required('Nome é obrigatório'),
})

const FunnelsEditModalForm: FC<Props> = ({funnel, funnelStage, isLoading, onCloseModal}) => {
  const funnelDispatch = useFunnelDispatch()

  const cancel = (withRefresh?: boolean) => {
    onCloseModal()
  }

  const [dataForEdit] = useState<FunnelStage>({
    ...funnelStage,
    description: funnelStage.description ? funnelStage.description : '',
    color: funnelStage.color ? funnelStage.color : '#adcfd2',
  })

  const formik = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        if (isNotEmpty(values.id)) {
          let updatedStep = await updateFunnelStage(funnel.id, values)
          funnelDispatch({
            type: 'update-funnel-step',
            data: updatedStep,
          })
        } else {
          let createdStep = await createFunnelStage(funnel.id, values)
          funnelDispatch({
            type: 'add-funnel-step',
            data: createdStep,
          })
        }
        cancel(true)
      } catch (ex) {
        console.error(ex)
        Swal.fire({
          title: 'Opss..',
          text: 'Houve um problema ao salvar a etapa.',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  console.log(formik.values)

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <InputFieldSet label={'Nome Etapa'}>
              <input
                placeholder=''
                {...formik.getFieldProps('title')}
                type='text'
                name='title'
                className={clsx(
                  'form-control fieldset-input',
                  {'is-invalid': formik.touched.title && formik.errors.title},
                  {
                    'is-valid': formik.touched.title && !formik.errors.title,
                  }
                )}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                disabled={formik.isSubmitting || isLoading}
              />
            </InputFieldSet>
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <InputFieldSet label={<>{'Cor '}</>}>
              <input
                placeholder=''
                {...formik.getFieldProps('color')}
                type='color'
                name='color'
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                disabled={formik.isSubmitting || isLoading}
              />
            </InputFieldSet>
          </div>

          <div className='fv-row mb-7'>
            <InputFieldSet label={'Descrição'}>
              <textarea
                placeholder=''
                {...formik.getFieldProps('description')}
                name='description'
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                disabled={formik.isSubmitting || isLoading}
              />
            </InputFieldSet>
          </div>
        </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-sm btn-wl-custom-secondary-collor me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Fechar
          </button>

          <button
            type='submit'
            className='btn btn-sm  btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export {FunnelsEditModalForm}
