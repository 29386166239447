import {FC} from 'react'

type Props = {
  actionId: number
}


const ActionIdCell: FC<Props> = ({ actionId }) => {

  const actionLabel =
    actionId == 1 ? 'Criar Tarefa' :
    actionId == 2 ? 'Mover Etapa' :
    actionId == 3 ? 'Alterar Status' :
    actionId == 4 ? 'Duplicar ticket/oportunidade' :
    actionId == 5 ? 'Criar card' :
    'Não identificado'

  return (
    <>
      {actionLabel}
    </>
  )
}


export {ActionIdCell}