/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'

const MAX_PAGES = 5
const MAX_BETWEEN_PAGES = (MAX_PAGES - 1) / 2

const ListPaginationLocal = ({ pagination, updatePage, isLoading }:any) => {
  if (!pagination || pagination.pagesCount <= 1) {
    return null
  }

  let pages = []
  if (pagination.pagesCount > MAX_PAGES) {
    if (pagination.page > pagination.pagesCount - MAX_PAGES + 1) {
      for (let i = pagination.pagesCount - MAX_PAGES + 1; i <= pagination.pagesCount; i++) {
        pages.push(i)
      }
    } else if (pagination.page > MAX_BETWEEN_PAGES) {
      const lastPage =
        pagination.page + MAX_BETWEEN_PAGES > pagination.pagesCount
          ? pagination.pagesCount
          : pagination.page + MAX_BETWEEN_PAGES
      for (let i = pagination.page - MAX_BETWEEN_PAGES; i <= lastPage; i++) {
        pages.push(i)
      }
    } else {
      for (let i = 1; i <= MAX_PAGES; i++) {
        pages.push(i)
      }
    }
  } else {
    for (let i = 1; i <= pagination.pagesCount; i++) {
      pages.push(i)
    }
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
        {/* Informações adicionais, se necessário */}
      </div>
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            {pagination.page > 1 && (
              <>
                <li
                  key="Primeiro"
                  className={clsx('page-item', {
                    disabled: isLoading,
                  })}
                >
                  <a
                    className="page-link page-text"
                    onClick={() => updatePage(1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="fa-solid fa-angles-left" style={{ fontSize: '1rem' }}></i>
                  </a>
                </li>
                <li
                  key="Anterior"
                  className={clsx('page-item', {
                    disabled: isLoading,
                  })}
                >
                  <a
                    className="page-link page-text"
                    onClick={() => updatePage(pagination.page - 1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="fa-solid fa-angle-left" style={{ fontSize: '1rem' }}></i>
                  </a>
                </li>
              </>
            )}

            {pages.map((pageNum) => (
              <li
                key={pageNum}
                className={clsx('page-item', {
                  active: pagination.page === pageNum,
                  disabled: isLoading,
                })}
              >
                <a
                  className="page-link"
                  onClick={() => updatePage(pageNum)}
                  style={{ cursor: 'pointer' }}
                >
                  {pageNum}
                </a>
              </li>
            ))}

            {pagination.page < pagination.pagesCount && (
              <>
                <li
                  key="Próximo"
                  className={clsx('page-item', {
                    disabled: isLoading,
                  })}
                >
                  <a
                    className="page-link page-text"
                    onClick={() => updatePage(pagination.page + 1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="fa-solid fa-angle-right" style={{ fontSize: '1rem' }}></i>
                  </a>
                </li>
                <li
                  key="Último"
                  className={clsx('page-item', {
                    disabled: isLoading,
                  })}
                >
                  <a
                    className="page-link page-text"
                    onClick={() => updatePage(pagination.pagesCount)}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="fa-solid fa-angles-right" style={{ fontSize: '1rem' }}></i>
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export { ListPaginationLocal }
