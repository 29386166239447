import { ErrorMessage, Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import * as Yup from "yup";
import { KTCardBody } from "../../../../../_metronic/helpers/components/KTCardBody";
import { INTEGRATION_PROVIDER, INTEGRATION_STATUS, INTEGRATION_TYPE, IntegrationEmail } from "../core/_models";
import { activeCustomerIntegrationEmail, activeUserIntegrationEmail, createCustomerIntegrationEmail, createUserIntegrationEmail, getCustomerIntegrationEmailByCustomer, getUserIntegrationEmailByCustomer, undoneCustomerIntegrationEmail, undoneUserIntegrationEmail } from "../core/_requests";

import Swal from 'sweetalert2'
import { KTIcon } from "../../../../../_metronic/helpers";
const spinnerStyle = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const spinnerTextStyle = {
    color: '#93a4ee',
    fontWeight: 'bold',
    padding: 50,
    margin: 50,
}
const IntegrationEmailFormModal = ({ onClose, userConfig }: any) => {
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(false)
    const [integrationEmail, setIntegrationEmail] = useState<IntegrationEmail | null>(null);
    const [showPassword, setShowPassword] = useState(false);
    const validationSchema = Yup.object({
        host: Yup.string().required("Campo obrigatório"),
        email: Yup.string().required("Campo obrigatório"),
        securityType: Yup.string().required("Campo obrigatório"),
        port: Yup.number().typeError("Deve ser um número").required("Campo obrigatório"),
        password: Yup.string().required("Campo obrigatório"),
    });

    const getData = async () => {
        setLoading(true);
        try {
            const response: null | IntegrationEmail = userConfig ? await getUserIntegrationEmailByCustomer() : await getCustomerIntegrationEmailByCustomer();
            if (response) {
                setIntegrationEmail(response);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
        
    };

    useEffect(() => {
        getData();
    }, []);

    const handleSubmit = async (values: any, { setSubmitting }: any) => {
        setLoadingForm(true);
        try {
            if (integrationEmail?.id && integrationEmail.status == INTEGRATION_STATUS.DISABLED) {
                let dataToSend = { ...integrationEmail }
                const config = {
                    host: values.host,
                    port: values.port,
                    emailAddress: values.email,
                    displayName: values.displayName,
                    securityType: values.securityType,
                    password: values.password,
                }
                dataToSend.config = config
                userConfig ? await activeUserIntegrationEmail(dataToSend) : await activeCustomerIntegrationEmail(dataToSend);
            } else {
                const config = {
                    host: values.host,
                    port: values.port,
                    emailAddress: values.email,
                    displayName: values.displayName,
                    securityType: values.securityType,
                    password: values.password,
                };
                userConfig ?
                    await createUserIntegrationEmail({
                        providerId: INTEGRATION_PROVIDER.SMTP,
                        config: config,
                    })
                    : await createCustomerIntegrationEmail({
                        providerId: INTEGRATION_PROVIDER.SMTP,
                        config: config,
                    });
            }
            Swal.fire('E-mail configurado com sucesso', '', 'success')
            await getData()
        } catch (error: any) {
            let text = 'Erro ao configurar e-mail, tente novamente mais tarde...'
            if (error?.response?.data?.message) {
                text = error?.response?.data?.message
            }
            Swal.fire({
                title: 'Opss..',
                text: text,
                icon: 'error',
                confirmButtonText: 'OK',
            })
        } finally {
            setLoadingForm(false);
            setSubmitting(false);
        }
    };

    const confirmUndone = () => {
        Swal.fire({
            title: 'Desativar Email',
            text: 'Tem certeza que deseja desativar o e-mail?',
            showDenyButton: true,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleUndone()
            } else if (result.isDenied) {
                //Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    const handleUndone = async () => {
        if (!integrationEmail) return
        setLoadingForm(true);
        try {
            userConfig ? await undoneUserIntegrationEmail(integrationEmail?.id) : await undoneCustomerIntegrationEmail(integrationEmail?.id);
            setIntegrationEmail(null)
            Swal.fire('E-mail desativado com sucesso', '', 'success')
            await getData()
        } catch (error: any) {
            let text = 'Erro ao desativar e-mail, tente novamente mais tarde...'
            Swal.fire({
                title: 'Opss..',
                text: text,
                icon: 'error',
                confirmButtonText: 'OK',
            })
        } finally {
            setLoadingForm(false);
        }
    };

    return (
        loading ? (
            <div style={spinnerStyle}>
                <Spinner style={spinnerTextStyle} />
            </div>
        ) : (
            <>
                <KTCardBody className='p-4'>
                    <h4 style={{ fontWeight: 'lighter' }}>Configuração manual</h4>
                    <Formik enableReinitialize initialValues={{
                        id: integrationEmail?.id || "",
                        host: integrationEmail?.config?.host || "",
                        port: integrationEmail?.config?.port?.toString() || "",
                        email: integrationEmail?.config?.emailAddress || "",
                        displayName: integrationEmail?.config?.displayName || "",
                        securityType: integrationEmail?.config?.securityType || "",
                        password: integrationEmail?.config?.password || "",
                    }} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ isSubmitting }) => (
                            <Form style={{ display: "flex", flexDirection: "column", gap: "15px" }}>

                                <div style={{ display: "flex", gap: "15px" }}>
                                    <div style={{ flex: 1 }}>
                                        <Field disabled={integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED} type="text" name="host" className="form-control" placeholder="Host*" />
                                        <ErrorMessage name="host" component="div" className="text-danger" />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Field disabled={integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED} type="text" name="port" className="form-control" placeholder="Porta*" />
                                        <ErrorMessage name="port" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div style={{ display: "flex", gap: "15px" }}>
                                    <div style={{ flex: 1 }}>
                                        <Field disabled={integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED} type="text" name="email" className="form-control" placeholder="Endereço de e-mail*" />
                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ position: 'relative' }}>
                                            <Field
                                                disabled={integrationEmail?.status === INTEGRATION_STATUS.ACTIVATED}
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                className="form-control"
                                                placeholder="Senha*"
                                            />
                                            <div
                                                onClick={() => setShowPassword((prev) => !prev)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: '10px',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                    zIndex: 2
                                                }}
                                            >
                                                {showPassword ? (
                                                    <KTIcon iconName="eye" className="fs-1" />
                                                ) : (
                                                    <KTIcon iconName="eye-slash" className="fs-1" />
                                                )}
                                            </div>
                                        </div>
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="text-danger"
                                        />
                                    </div>

                                </div>

                                <div style={{ display: "flex", gap: "15px" }}>
                                    <div style={{ flex: 1 }}>
                                        <Field disabled={integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED} as="select" name="securityType" className="form-control">
                                            <option value="">Tipo de segurança*</option>
                                            <option value="tls">TLS</option>
                                            <option value="ssl">SSL</option>
                                        </Field>
                                        <ErrorMessage name="securityType" component="div" className="text-danger" />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Field disabled={integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED} type="text" name="displayName" className="form-control" placeholder="Nome de exibição*" />
                                        <ErrorMessage name="displayName" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div style={{ justifyContent: 'center', alignItems: "center", display: 'flex' }}>
                                    {integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED ? (
                                        <button
                                            type="button"
                                            style={{
                                                padding: "10px",
                                                width: '20%',
                                                textAlign: "center",
                                                borderRadius: '8px',
                                                backgroundColor: '#FF0000',
                                                color: '#FFFF'
                                            }}
                                            className='btn btn-sm fw-bold'
                                            onClick={confirmUndone}
                                            disabled={isSubmitting || loading || loadingForm}
                                        >
                                            {loadingForm ? <Spinner size="sm" /> : "Desativar"}
                                        </button>
                                    ) :
                                        (
                                            <button
                                                type="submit"
                                                style={{
                                                    padding: "10px",
                                                    width: '20%',
                                                    textAlign: "center",
                                                    borderRadius: '8px'
                                                }}
                                                className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
                                                disabled={isSubmitting || loading || loadingForm}
                                            >
                                                {loadingForm ? <Spinner size="sm" /> : integrationEmail?.status == INTEGRATION_STATUS.DISABLED ? "Ativar" : "Salvar"}
                                            </button>
                                        )}
                                </div>

                            </Form>
                        )}
                    </Formik>
                </KTCardBody >

            </>
        )


    )
}

export { IntegrationEmailFormModal }