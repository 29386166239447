import {createContext, useContext, useReducer} from 'react'

export interface CardSendEmailModalContextModel {
  emailMessage: any
  open: boolean
  formAction?: string
  card: any
  contact?: any
}

const initialData = {
  open: false,
  card: null,
  emailMessage: null,
}

export const CardSendEmailModalContext = createContext<CardSendEmailModalContextModel>(initialData)
export const CardSendEmailModalDispatchContext = createContext<any>(null)

export function CardSendEmailModalProvider({children}: any) {
  const [data, dispatch] = useReducer(cardSendEmailModalReducer, initialData)

  return (
    <CardSendEmailModalContext.Provider value={data}>
      <CardSendEmailModalDispatchContext.Provider value={dispatch}>
        {children}
      </CardSendEmailModalDispatchContext.Provider>
    </CardSendEmailModalContext.Provider>
  )
}

export function useCardSendEmailModal() {
  return useContext(CardSendEmailModalContext)
}

export function useCardSendEmailModalDispatch() {
  return useContext(CardSendEmailModalDispatchContext)
}

function cardSendEmailModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return {
        ...data,
        formAction: 'add',
        open: true,
        card: action.data,
        emailMessage: null,
        contact: action.contactId,
      }
    }
    case 'open-edit-modal': {
      return {
        ...data,
        formAction: 'edit',
        open: true,
        card: action.data,
        emailMessage: action.emailMessage,
        contact: action.contactId,
      }
    }
    case 'close-modal': {
      return {
        ...data,
        formAction: undefined,
        open: false,
        card: null,
        emailMessage: null,
        contact: null,
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}
