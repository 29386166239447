/* eslint-disable jsx-a11y/anchor-is-valid */
import {initialQueryState, KTCard} from '../../../_metronic/helpers'
import {CardsListHeader} from './components/header/CardsListHeader'
import {CardsTable} from './table/CardsTable'
import {CardsFormModal} from './edit-modal/CardsFormModal'

import {CardDetailModal} from './detail-modal/CardDetailModal'
import {useCardFormModalDispatch, useCardFormModal} from './core/CardFormModalContext'
import {useEffect, useState} from 'react'
import {CARD_TYPES} from './core/_models'
import {useLocation} from 'react-router-dom'

import {CardMoveModalProvider} from './core/CardMoveModalContext'
import {CardMoveModal} from '../cards/components/move-card-modal/CardMoveModal'

import {CardTransferResponsibleModalProvider} from '../cards/core/CardTransferResponsibleModalContext'
import {CardTransferResponsibleModal} from '../cards/components/transfer-responsible-modal/CardTransferResponsibleModal'

import {CardMarkModalProvider} from '../cards/core/CardMarkModalContext'
import {CardMarkModal} from '../cards/components/mark-modal/CardMarkModal'

import {CardArchiveModalProvider} from '../cards/core/CardArchiveModalContext'
import {CardArchiveModal} from '../cards/components/archive-modal/CardArchiveModal'

import {CardFreezeModalProvider} from '../cards/core/CardFreezeModalContext'
import {CardFreezeModal} from '../cards/components/freeze-modal/CardFreezeModal'
import {CardFormAddPrimaryDataModal} from './edit-modal/CardFormAddPrimaryDataModal'
import {useQueryRequest} from '../../modules/view/QueryRequestProvider'
import {CardsList} from './embed/CardsList'
import {FunnelProvider} from '../funnel/core/FunnelContext'
import {CardSendEmailModalProvider} from './core/CardSendEmailModalContext'
import {CardSendEmailModal} from './components/card-send-email/CardSendEmailModal'

const CardsPage = ({type}: {type: number}) => {
  const location = useLocation()
  const {updateState} = useQueryRequest()
  const cardFormModalDispatch = useCardFormModalDispatch()
  const cardFormModal = useCardFormModal()
  const [isMobile, setIsMobile] = useState(false)
  const [isEmbed, setIsEmbed] = useState(false)
  const [contactIdUrl, setContactIdUrl] = useState<any>(null)
  //Analisar URL
  useEffect(() => {
    let regex = null
    if (CARD_TYPES?.OPPORTUNITY?.id == type) {
      regex = /^\/opportunities\/(details|edit|add)\/*([^/]+)*$/
    } else {
      regex = /^\/tickets\/(details|edit|add)\/*([^/]+)*$/
    }

    const match = location?.pathname?.match(regex)

    let queryVars: any = Object.fromEntries(new URLSearchParams(location.search))
    if (queryVars.contactId) {
      const contactId = Number(queryVars.contactId)
      setContactIdUrl(contactId)
      updateState({
        filter: {
          contactId: contactId,
        },
        ...initialQueryState,
      })
    } else setContactIdUrl(null)
    if (queryVars.isEmbed && queryVars.isEmbed == 'true') {
      setIsEmbed(true)
      const wrapper = document.getElementById('kt_app_sidebar')
      if (wrapper) {
        wrapper.style.display = 'none'
      }
    }
    if (match && match.length > 0) {
      let action = null
      switch (match[1]) {
        case 'details':
          action = 'open-detail-modal'
          break
        case 'edit':
          action = 'open-edit-modal'
          break
        case 'add':
          action = 'open-add-primary-data-modal'
          break
      }

      if (action == 'open-add-primary-data-modal') {
        cardFormModalDispatch({
          type: action,
          data: {
            type: type,
            customData: queryVars,
          },
        })
      } else {
        const parameter = match[2]
        if (parameter && parameter !== '') {
          cardFormModalDispatch({
            type: action,
            data: {
              id: parameter,
              type: type,
            },
          })
        }
      }
    } else if (cardFormModal.open) {
      cardFormModalDispatch({
        type: 'close-modal',
      })
    }
  }, [location?.pathname])

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 820px)')
    const handleResize = () => {
      setIsMobile(mediaQuery.matches)
    }
    mediaQuery.addEventListener('change', handleResize)
    handleResize()
    return () => {
      mediaQuery.removeEventListener('change', handleResize)
    }
  }, [])

  return (
    <>
      <CardsListHeader type={type} embed={isEmbed} />
      <CardArchiveModalProvider>
        <CardFreezeModalProvider>
          <CardMarkModalProvider>
            <CardSendEmailModalProvider>
              <CardTransferResponsibleModalProvider>
                <CardMoveModalProvider>
                  <FunnelProvider>
                    <CardsFormModal embed={isEmbed} contactIdUrl={contactIdUrl} />
                    <CardDetailModal embed={isEmbed} contactIdUrl={contactIdUrl} />
                    <CardFreezeModal />
                    <CardTransferResponsibleModal />
                    <CardMarkModal />
                    <CardArchiveModal />
                    <CardMoveModal />
                    <CardSendEmailModal />

                    {isMobile ? (
                      <CardsList type={type} />
                    ) : (
                      <KTCard>
                        <CardsTable />
                      </KTCard>
                    )}
                  </FunnelProvider>
                </CardMoveModalProvider>
              </CardTransferResponsibleModalProvider>
            </CardSendEmailModalProvider>
          </CardMarkModalProvider>
        </CardFreezeModalProvider>
      </CardArchiveModalProvider>
      <CardFormAddPrimaryDataModal embed={isEmbed} />
    </>
  )
}

export {CardsPage}
