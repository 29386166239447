import { useEffect, useState } from 'react'
import { useQueryRequest } from '../../../../../modules/view/QueryRequestProvider'
import { useQueryResponse } from '../../../../../modules/view/QueryResponseProvider'
import { initialQueryState } from '../../../../../../_metronic/helpers'
import { InputFieldSet } from '../../../../../../_metronic/partials/inputs/InputFieldSet'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import SelectAsync from 'react-select/async'
import debounce from 'debounce-promise'
import { getFunnels } from '../../../../funnel/core/_requests'
import { components } from 'react-select'
import SelectInputCustomStyle from '../../../../../modules/view/styles/SelectInputCustomStyle'
import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'
const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &::after {
    display: none;
  }
  position: relative;
  top: 2px;
`
const ClearIndicator = (props: any) => {
  const { children = <components.ClearIndicator {...props} />, clearValue, innerProps } = props

  return (
    <div
      {...innerProps}
      onMouseDown={(e) => {
        e.stopPropagation() 
        clearValue() 
      }}
    >
      {children}
    </div>
  )
}
const AutomaticActionsFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [status, setStatus] = useState<any>()
  const [funnel, setFunnel] = useState<any>()
  const [show, setShow] = useState(false) 

  const resetData = () => {
    setStatus(null)
    setFunnel(null)
    updateState({ filter: undefined, ...initialQueryState })
    setShow(false) 
  }

  const filterData = () => {
    const dataToSend = {
      status,
      ...(funnel?.value && { funnelId: funnel.value })
    }

    updateState({
      filter: dataToSend,
      ...initialQueryState,
    })
    setShow(false) 
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const funnelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let funnels = await getFunnels('page=1&perPage=200&search=' + encodeURIComponent(inputValue))
      if (funnels && funnels != undefined) {
        for (let i = 0; i < funnels?.length; i++) {
          list.push({ label: funnels[i].name, value: funnels[i].id })
        }
      }
      resolve(list)
    })
  const debounceFunnelsOptions = debounce(funnelsOptions, 500)

  return (
    <Dropdown
      id='dropdown-true'
      show={show}
      onToggle={(nextShow) => setShow(nextShow)}
    >
      <StyledDropdownToggle
        variant='wl-custom-primary-collor-inverse'
        id='dropdown-basic'
        className='btn btn-sm me-3'
      >
        <i className='fa-solid fa-filter fs-2'></i>
        Filtros
      </StyledDropdownToggle>

      <Dropdown.Menu className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'>
        <Dropdown.Header className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Opções de Filtros</div>
        </Dropdown.Header>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-7'>
            <InputFieldSet label={'Funil'}>
              <SelectAsync
                placeholder={'Selecionar funil'}
                name={'funnel_id'}
                isClearable
                loadOptions={debounceFunnelsOptions}
                value={funnel}
                onChange={(selectedOption: any) => setFunnel(selectedOption)}
                className='fieldset-input'
                noOptionsMessage={() => 'Sem Funil'}
                styles={SelectInputCustomStyle}
                components={{ ClearIndicator }}
              />
            </InputFieldSet>
          </div>
          <div className='mb-7'>
            <InputFieldSet label={"Status"}>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-control form-select form-select-sm fieldset-input'
                defaultValue=''
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value=''>Todos</option>
                <option value='1'>Ativo</option>
                <option value='0'>Inativo</option>
              </select>
              
            </InputFieldSet>
          </div>
        </div>

        <div className='d-flex justify-content-end p-3 mt-2'>
          <button
            type='button'
            disabled={isLoading}
            onClick={resetData}
            className='btn btn-sm btn-wl-custom-secondary-collor fw-bold me-2 px-6'
          >
            Limpar
          </button>
          <button
            type='button'
            disabled={isLoading}
            onClick={filterData}
            className='btn btn-sm btn-wl-custom-primary-collor fw-bold px-6'
          >
            Aplicar
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { AutomaticActionsFilter }
