import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ListLoading } from '../../../../modules/view/ListLoading'
import Swal from 'sweetalert2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { archiveCard } from '../../core/_requests'
import format from 'date-fns/format'
import { useFunnelDispatch } from '../../../funnel/core/FunnelContext'
import { useCardArchiveModalDispatch } from '../../core/CardArchiveModalContext'
import Form from 'react-bootstrap/Form';

type Props = {
  card: any
}

const editDataSchema = Yup.object().shape({})

const CardArchiveModalForm: FC<Props> = ({ card }) => {

  const funnelDispatch = useFunnelDispatch();
  const cardArchiveModalDispatch = useCardArchiveModalDispatch();

  const [dataForEdit] = useState<any>({
    data: new Date(),
    hora: new Date(),
    unarchive: false
  })

  const formik = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      if (values.unarchive && (!values.data || !values.hora)) return Swal.fire({
        title: 'Opss..',
        text: 'Preencha os Campos corretamente.',
        icon: 'error',
        confirmButtonText: 'OK'
      })

      try {

        let datetime = null;
        if(values.unarchive && values.data && values.hora) datetime = format(values.data, 'yyyy-MM-dd') + " " + format(values.hora, 'HH:mm:ss');

        let opUpdated = await archiveCard(card.id, { archiveUntilDate: datetime });

        if(funnelDispatch)
        {
          if (opUpdated) {
            card.status = opUpdated.status;
          }

          funnelDispatch({
            type: "update-step-card",
            data: card
          })
        }
        if(card.onClose){
          await card.onClose()
        }

        cardArchiveModalDispatch({
          type: "close-modal"
        })

        Swal.fire('Arquivado com Sucesso!', '', 'success')

      } catch (ex) {
        console.error(ex)
        Swal.fire({
          title: 'Opss..',
          text: 'Houve um problema ao arquivar.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>

      <form id='kt_modal_archive_card' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
     
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_archive_card_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_archive_card_header'
          data-kt-scroll-wrappers='#kt_modal_archive_card_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}

          <Form.Check type={"switch"} id={`active`} reverse style={{ textAlign: "left", marginTop: "14px" }}>
          <Form.Check.Label className='label-card'><strong>Definir data para desarquivar</strong></Form.Check.Label>
          <Form.Check.Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.unarchive }
              checked={formik.values.unarchive ? true : false}
              type={"checkbox"}
              name={"unarchive"}
          />
          </Form.Check>
          
          { formik.values.unarchive && (
            <>
          <p className='label-card'>Manter Arquivado até</p>
          <DatePicker
            {...formik.getFieldProps('data')}
            label="Data"
            className='mb-7'
            value={formik.values.data}
            onChange={value =>
              formik.setFieldValue("data", value)
            }
          />

          <MobileTimePicker
            value={formik.values.hora}
            onChange={value =>
              formik.setFieldValue("hora", value)
            }
            label="Hora"
            //disablePast={true}
            localeText={{ cancelButtonLabel: "Cancelar", okButtonLabel: "OK", toolbarTitle: "Selecionar Hora" }}
          />
          </>)}

        </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Arquivar</span>
            {(formik.isSubmitting) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting) && <ListLoading />}
    </>
  )
}

export { CardArchiveModalForm }
