// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { AutomaticAction } from '../../core/_models'
import {useAutomaticActionsFormModalDispatch} from '../../core/AutomaticActionsFormModalContext'
type Props = {
  row: Row<AutomaticAction>
}

const CustomRow: FC<Props> = ({ row }) => {
  const automaticActionsModalDispatch = useAutomaticActionsFormModalDispatch()
  const openDetailModal = (event) => {
    if (event.target?.localName == 'td') {
      if (row.original?.id) {
        automaticActionsModalDispatch({
          type: 'open-edit-modal',
          data: row.original?.id
        })
      }
    }
  }

  return (
    <tr {...row.getRowProps()} style={{cursor: 'pointer'}} onClick={openDetailModal}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )

}

export { CustomRow }
