import {
  moveCard,
  markSolveCard,
  markUnsolveCard,
  deleteCard,
  markWinCard,
  markLostCard,
  reopenCard,
} from '../../core/_requests'

import { TERMINATION_REASON_TYPES } from '../../../settings/termination-reasons/core/_models'

import { MenuComponent } from '../../../../../_metronic/assets/ts/components'

import { useCardTransferResponsibleModalDispatch } from '../../core/CardTransferResponsibleModalContext'
import { useCardArchiveModalDispatch } from '../../core/CardArchiveModalContext'
import { useCardFreezeModalDispatch } from '../../core/CardFreezeModalContext'
import { useCardMarkModalDispatch } from '../../core/CardMarkModalContext'
import { useCardMoveModalDispatch } from '../../core/CardMoveModalContext'
import { CARD_TYPES, CARD_STATUS } from '../../core/_models'
import { useCardFormModalDispatch } from '../../core/CardFormModalContext'
import { useEffect } from 'react'

import Swal from 'sweetalert2'

const execDeleteCard = async (op: any, onExecAction: any) => {
  try {

    await deleteCard(op.id)

    onExecAction('delete-card', op);

    Swal.fire('Registro Excluído!', '', 'success')
  } catch (e) {
    console.log(e);
    Swal.fire({
      title: 'Opss..',
      text: 'Houve um problema ao excluir o registro.',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }
}

const confirmDeleteCard = (op: any, onExecAction: any) => {
  Swal.fire({
    title: 'Excluir Registro',
    text: 'Tem certeza que deseja excluir o registro?',
    showDenyButton: true,
    confirmButtonText: 'Sim',
    denyButtonText: `Não`,
  }).then((result) => {
    if (result.isConfirmed) {
      execDeleteCard(op, onExecAction)
    } else if (result.isDenied) {
      //Swal.fire('Changes are not saved', '', 'info')
    }
  })
}

const execreopenCard = async (op: any, onExecAction: any) => {
  try {

    let opUpdated = await reopenCard(op.id)

    if (opUpdated) {
      op.status = opUpdated.status
    }

    onExecAction('reopen-card', op);

    Swal.fire('Registro foi reaberto!', '', 'success')
  } catch (e) {
    Swal.fire({
      title: 'Opss..',
      text: 'Houve um problema ao alterar o registro.',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }
}

const execmarkWinCard = async (op: any, onExecAction: any) => {
  try {
    let opUpdated = await markWinCard(op.id, {
      terminationReason: null,
      terminationSubreason: null
    })

    if (opUpdated) {
      op.status = opUpdated.status
    }

    onExecAction('card-mark-win', op);

    Swal.fire('Oportunidade marcada como Ganha!', '', 'success')
  } catch (e) {
    Swal.fire({
      title: 'Opss..',
      text: 'Houve um problema ao alterar o registro.',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }
}

const execMarkLostCard = async (op: any, onExecAction: any) => {
  try {
    let opUpdated = await markLostCard(op.id, {
      terminationReason: null,
      terminationSubreason: null
    })

    if (opUpdated) {
      op.status = opUpdated.status
    }

    onExecAction('card-mark-lost', op);

    Swal.fire('Oportunidade marcada como Perdida!', '', 'success')
  } catch (e) {
    Swal.fire({
      title: 'Opss..',
      text: 'Houve um problema ao alterar o registro.',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }
}

const execMarkSolveCard = async (op: any, onExecAction: any) => {
  try {
    let opUpdated = await markSolveCard(op.id, {
      terminationReason: null,
      terminationSubreason: null
    })

    if (opUpdated) {
      op.status = opUpdated.status
    }

    onExecAction('card-mark-solved', op);

    Swal.fire('Marcado como Resolvido!', '', 'success')
  } catch (e) {
    Swal.fire({
      title: 'Opss..',
      text: 'Houve um problema ao alterar o registro.',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }
}

const execMarkUnsolveCard = async (op: any, onExecAction: any) => {
  try {
    let opUpdated = await markUnsolveCard(op.id, {
      terminationReason: null,
      terminationSubreason: null
    })

    if (opUpdated) {
      op.status = opUpdated.status
    }

    onExecAction('card-mark-unsolved', op);

    Swal.fire('Marcado como Não Resolvido!', '', 'success')
  } catch (e) {
    Swal.fire({
      title: 'Opss..',
      text: 'Houve um problema ao alterar o registro.',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }
}

type Props = {
  onExecAction: any
  card: any
  isMobile?:boolean
}

const CardDropDownActions = ({ onExecAction, card, isMobile=false }: Props) => {

  const cardFormModalDispatch = useCardFormModalDispatch()
  const cardFreezeModalDispatch = useCardFreezeModalDispatch()
  const cardTransferResponsibleModalDispatch = useCardTransferResponsibleModalDispatch()
  const cardArchiveModalDispatch = useCardArchiveModalDispatch()
  const cardMarkModalDispatch = useCardMarkModalDispatch()
  const cardMoveModalDispatch = useCardMoveModalDispatch()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  return (
    <div
      className='menu menu-sub menu-sub-dropdown funnel-stage-card-options dropdown-card'
      data-kt-menu='true'
    >
      <div className={isMobile?'px-2 py-2':'px-4 py-4'}>
        <ul style={isMobile?{fontSize:10}:{}}>
          {card.status != CARD_STATUS.OPEN.id && (
            <li>
              <a
                href='#'
                style={isMobile?{padding:6}:{}}
                onClick={() => {
                  execreopenCard(card, onExecAction)
                }}
              >
                <i className='fa-solid fa-arrows-rotate' style={{ marginRight: '3px', fontSize: isMobile ? '12px' : 'inherit' }}></i>
                Reabrir{' '}
                {CARD_TYPES.OPPORTUNITY.id == card.type
                  ? ' Oportunidade'
                  : CARD_TYPES.TICKET.id == card.type
                    ? 'Tícket'
                    : ''}
              </a>
            </li>
          )}
          {CARD_TYPES.OPPORTUNITY.id == card.type && card.status == CARD_STATUS.OPEN.id && (
            <li>
              <a
                href='#'
                style={isMobile?{padding:6}:{}}
                onClick={() => {
                  // if (card.funnel?.winReasonRequired) {
                  if(isMobile){
                    cardMarkModalDispatch({
                      type: 'open-modal',
                      data: {...card, onClose: () => onExecAction('card-mark-win', card) },
                      status: TERMINATION_REASON_TYPES.WIN
                    })
                  }else{
                    cardMarkModalDispatch({
                      type: 'open-modal',
                      data: card,
                      status: TERMINATION_REASON_TYPES.WIN
                    })
                    onExecAction('card-mark-win', card);
                  }
                  // }
                  // else execmarkWinCard(card, onExecAction)
                }}
              >
                <i className='fa-solid fa-trophy' style={{ marginRight: '3px',  fontSize: isMobile ? '12px' : 'inherit'}}></i> Marcar
                como Ganha
              </a>
            </li>
          )}
          {CARD_TYPES.OPPORTUNITY.id == card.type && card.status == CARD_STATUS.OPEN.id && (
            <li>
              <a
              style={isMobile?{padding:6}:{}}
                href='#'
                onClick={() => {
                  // if (card.funnel?.lostReasonRequired) {
                    if(isMobile){
                      cardMarkModalDispatch({
                        type: 'open-modal',
                        data: {...card, onClose: () => onExecAction('card-mark-lost', card) },
                        status: TERMINATION_REASON_TYPES.LOST
                      })
                    }
                      else{
                        cardMarkModalDispatch({
                          type: 'open-modal',
                          data: card,
                          status: TERMINATION_REASON_TYPES.LOST
                        })
                        onExecAction('card-mark-lost', card);
                      }
                
                  // }
                  // else {
                  //   execMarkLostCard(card, onExecAction)
                  // }
                }}
              >
                <i className='fa-regular fa-thumbs-down' style={{ marginRight: '3px', fontSize: isMobile ? '12px' : 'inherit'}}></i>{' '}
                Marcar como Perdida
              </a>
            </li>
          )}
          {CARD_TYPES.TICKET.id == card.type && card.status == CARD_STATUS.OPEN.id && (
            <li>
              <a
              style={isMobile?{padding:6}:{}}
                href='#'
                onClick={() => {
                  if(isMobile){
                    cardMarkModalDispatch({
                      type: 'open-modal',
                      data: {...card, onClose: () => onExecAction('card-mark-solved', card) },
                      status: TERMINATION_REASON_TYPES.SOLVED
                    })
                  }else{
                    cardMarkModalDispatch({
                      type: 'open-modal',
                      data: card,
                      status: TERMINATION_REASON_TYPES.SOLVED
                    })
                    onExecAction('card-mark-solved', card);
                  }
                  
                }}
              >
                <i className='fa-solid fa-trophy' style={{ marginRight: '3px', fontSize: isMobile ? '12px' : 'inherit' }}></i> Marcar
                como Resolvido
              </a>
            </li>
          )}
          {CARD_TYPES.TICKET.id == card.type && card.status == CARD_STATUS.OPEN.id && (
            <li>
              <a
              style={isMobile?{padding:6}:{}}
                href='#'
                onClick={() => {
                  if(isMobile){
                    cardMarkModalDispatch({
                      type: 'open-modal',
                      data: {...card, onClose: () =>  onExecAction('card-mark-unsolved', card) },
                      status: TERMINATION_REASON_TYPES.UNSOLVED
                    })
                  }else{
                    cardMarkModalDispatch({
                      type: 'open-modal',
                      data: card,
                      status: TERMINATION_REASON_TYPES.UNSOLVED
                    })
                    onExecAction('card-mark-unsolved', card);
                  }
                  
                }}
              >
                <i className='fa-regular fa-thumbs-down' style={{ marginRight: '3px', fontSize: isMobile ? '12px' : 'inherit' }}></i>{' '}
                Marcar como Não Resolvido
              </a>
            </li>
          )}
          <li>
            <a
            style={isMobile?{padding:6}:{}}
              href='#'
              onClick={() => {
                onExecAction('card-edit', card);
                setTimeout(() => {
                  cardFormModalDispatch({
                    type: 'open-edit-modal',
                    data: { id: card.id, type: card.type, funnelId: card.funnel?.id? card.funnel?.id: card.funnelId },
                  })
                }, 50)

              }}
            >
              <i className='fa-solid fa-pencil' style={{ marginRight: '3px', fontSize: isMobile ? '12px' : 'inherit' }}></i> Editar
            </a>
          </li>
          <li>
            <a
            style={isMobile?{padding:6}:{}}
              href='#'
              onClick={() => {
                onExecAction('card-duplicate', card);
                setTimeout(() => {
                  cardFormModalDispatch({
                    type: 'open-duplicate-modal',
                    data: {
                      id: card.id,
                      type: card.type,
                      duplicate: true,
                      funnelId: card.funnel?.id? card.funnel?.id: card.funnelId,
                    },
                  })
                }, 50)

              }}
            >
              <i className='fa-regular fa-clone' style={{ marginRight: '3px', fontSize: isMobile ? '12px' : 'inherit' }}></i>{' '}
              Duplicar
            </a>
          </li>
          {CARD_TYPES.OPPORTUNITY.id == card.type && card.status == CARD_STATUS.OPEN.id && (
            <li>
              <a
              style={isMobile?{padding:6}:{}}
                href='#'
                onClick={() => {
                  if(isMobile){
                    cardFreezeModalDispatch({
                      type: 'open-modal',
                      data: {...card, onClose: () =>   onExecAction('card-freeze', card) },
                    })
                  }else{
                    cardFreezeModalDispatch({
                      type: 'open-modal',
                      data: card,
                    })
                    onExecAction('card-freeze', card);
                  }
                }}
              >
                <i className='fa-solid fa-snowflake' style={{ marginRight: '3px', fontSize: isMobile ? '12px' : 'inherit' }}></i>{' '}
                Congelar
              </a>
            </li>
          )}
          {CARD_TYPES.TICKET.id == card.type && card.status == CARD_STATUS.OPEN.id && (
            <li>
              <a
              style={isMobile?{padding:6}:{}}
                href='#'
                onClick={() => {
                  if(isMobile){
                    cardArchiveModalDispatch({
                      type: 'open-modal',
                      data: {...card, onClose: () =>  onExecAction('card-archive', card) }
                    })
                  }
                  else{
                    cardArchiveModalDispatch({
                      type: 'open-modal',
                      data: card,
                    })
                    onExecAction('card-archive', card);
                  }
                  
                }}
              >
                <i className='fa-solid fa-snowflake' style={{ marginRight: '3px',fontSize: isMobile ? '12px' : 'inherit' }}></i>{' '}
                Arquivar
              </a>
            </li>
          )}
          <li>
            <a
            style={isMobile?{padding:6}:{}}
              href='#'
              onClick={() => {
                if(isMobile){
                  cardTransferResponsibleModalDispatch({
                    type: 'open-modal',
                    data: {...card, onClose: () =>  onExecAction('card-transfer-responsible', card) }
                  })
                }
                else{
                  cardTransferResponsibleModalDispatch({
                    type: 'open-modal',
                    data: card,
                  })
                  onExecAction('card-transfer-responsible', card);
                }
              }}
            >
              <i className='fa-solid fa-right-left' style={{ marginRight: '3px', fontSize: isMobile ? '12px' : 'inherit' }}></i>{' '}
              Transferir Responsável
            </a>
          </li>
          <li>
            <a
            style={isMobile?{padding:6}:{}}
              href='#'
              onClick={() => {
                if(isMobile){
                  cardMoveModalDispatch({
                    type: 'open-modal',
                    data: {...card, onClose: () =>  onExecAction('card-move-step', card) }
                  })
                }else{
                  cardMoveModalDispatch({
                    type: 'open-modal',
                    data: card,
                  })
                  onExecAction('card-move-step', card);
                }
              }}
            >
              <i className='fa-solid fa-up-down-left-right' style={{ marginRight: '3px', fontSize: isMobile ? '12px' : 'inherit' }}></i>{' '}
              Mover {CARD_TYPES.OPPORTUNITY.id == card.type ? 'Oportunidade' : (CARD_TYPES.TICKET.id == card.type ? 'Tícket' : 'Card')}
            </a>
          </li>
          <li>
            <a
            style={isMobile?{padding:6}:{}}
              href='#'
              onClick={() => {
                confirmDeleteCard(card, onExecAction)
              }}
            >
              <i className='fa-solid fa-trash' style={{ marginRight: '3px', fontSize: isMobile ? '12px' : 'inherit' }}></i> Excluir
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default CardDropDownActions
