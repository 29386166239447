/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {MutableRefObject, useEffect, useRef, useState} from 'react'
import {SidebarMenuItemToggle} from './SidebarMenuItemToggle'
import {ToggleComponent} from '../../../../assets/ts/components'
import {useLayout} from '../../../core'
import {useAuth} from '../../../../../app/modules/auth'
import {useCustomLayout} from '../../../../../app/modules/layout/core/CustomLayout'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const SidebarMenuMain = (props: PropsType) => {
  const intl = useIntl()
  const {customLayout} = useCustomLayout()

  const toggleRef = useRef<HTMLDivElement>(null)
  const [toggleStateEnabled, setToggleStateEnabled] = useState<boolean>(true)
  const {config} = useLayout()
  const {currentUser, logout} = useAuth()

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null

      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating')

        //console.log("asd", toggleObj.isEnabled())
        setToggleStateEnabled(toggleObj.isEnabled())

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef])

  return (
    <>
      <SidebarMenuItemWithSub
        to='/dashboard'
        icon={toggleType == 'collapse' ? '' : 'fa-solid fa-chart-pie'}
        title='Dashboard'
        fontIcon='bi-app-indicator'
      >
        <SidebarMenuItem to='/dashboard/opportunity-analysis' title='Análise de Oportunidades' />
        <SidebarMenuItem to='/dashboard/ticket-analysis' title='Análise de Tickets' />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/funnel'
        icon={toggleType == 'collapse' ? '' : 'fa-solid fa-filter'}
        title='Funil'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/opportunities'
        icon={toggleType == 'collapse' ? '' : 'fa-solid fa-arrow-trend-up'}
        title='Oportunidades'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/tickets'
        icon={toggleType == 'collapse' ? '' : 'fa-solid fa-circle-exclamation'}
        title='Tíckets'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/contacts'
        icon={toggleType == 'collapse' ? '' : 'fa-solid fa-address-book'}
        title='Contatos'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/companies'
        icon={toggleType == 'collapse' ? '' : 'fa-solid fa-building'}
        title='Empresas'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/tasks'
        icon={toggleType == 'collapse' ? '' : 'fa-solid fa-list-check'}
        title='Tarefas'
        fontIcon='bi-app-indicator'
      />

      {currentUser?.role?.key === 'admin' && (
        <SidebarMenuItemWithSub
          to='#'
          title='Configurações'
          icon={toggleType == 'collapse' ? '' : 'fa-solid fa-gear'}
          fontIcon='bi-person'
        >
          <SidebarMenuItem to='/settings/funnels' title='Funis' hasBullet={true} />
          <SidebarMenuItem
            to='/settings/termination-reasons'
            title='Motivos de Finalização'
            hasBullet={true}
          />
          {(!currentUser?.customerAccount?.resellerSettings ||
            currentUser?.customerAccount?.resellerSettings?.subAccountManageUsersOnlyFromApi ==
              false) && (
            <>
              <SidebarMenuItem to='/users' title='Usuários' hasBullet={true} />

              <SidebarMenuItem to='/teams' title='Equipes' hasBullet={true} />
            </>
          )}

          <SidebarMenuItem to='/settings/labels' title='Etiquetas' hasBullet={true} />
          <SidebarMenuItem
            to='/settings/automatic-actions'
            title='Ações Automáticas (Novo)'
            hasBullet={true}
          />

          <SidebarMenuItem
            to='/settings/custom-fields'
            title='Campos Customizados'
            hasBullet={true}
          />

          <SidebarMenuItem to='/settings/forms' title='Formulários Customizados' hasBullet={true} />

          <SidebarMenuItem
            to='/settings/ticket-categories'
            title='Categorias de Tíckets'
            hasBullet={true}
          />

          <SidebarMenuItem to='settings/import' title='Importação' hasBullet={true} />
          <SidebarMenuItem
            to='/settings/integrations'
            title='Integrações'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}
      <SidebarMenuItemWithSub
          to='#'
          title='Minha Conta'
          icon={toggleType == 'collapse' ? '' : 'fa-solid fa-person'}
          fontIcon='bi-person'
        >
          <SidebarMenuItem
            to='/my-profile/config'
            title='Configurações'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

      {customLayout?.useEmbed == false && (
        <div className='menu-item'>
          <a onClick={logout} className={'menu-link'}>
            Sair{' '}
            <span
              style={
                toggleType == 'collapse'
                  ? {
                      fontSize: '0.65rem',
                      color: 'red',
                      marginLeft: '4px',
                      opacity: 1,
                      transition: 'opacity 0.4s, visibility 0.4s',
                    }
                  : {
                      fontSize: '0.65rem',
                      color: 'red',
                      marginLeft: '4px',
                      opacity: 0,
                      visibility: 'hidden',
                      transition: 'opacity 0.3s, visibility 0.3s',
                    }
              }
            ></span>
          </a>
        </div>
      )}

      <br />
      <br />
      <SidebarMenuItemToggle
        toggleRef={toggleRef}
        toggleState={toggleState}
        toggleType={toggleType}
        toggleStateEnabled={toggleStateEnabled}
      />
    </>
  )
}

export {SidebarMenuMain}
