import { useEffect, useMemo, useState } from 'react'
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../../modules/view/QueryResponseProvider'
import { ListLoading } from '../../../modules/view/ListLoading'
import { KTCardBody, PAGINATION_PERPAGE } from '../../../../_metronic/helpers'
import { useFunnelDispatch } from '../../funnel/core/FunnelContext'
import { getCards } from '../core/_requests'
import { useLocation, useNavigate } from 'react-router-dom'
import CardDropDownActions from '../components/actions/CardDropDownActions'
import { CARD_TYPES } from '../core/_models'

const CardsList = ({ type }: { type: number }) => {
  const list = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => list, [list])
  const location = useLocation();
  const [allData, setAllData] = useState<any>({
    total_items: 0,
    total_pages: 0,
    current_page: 1,
    data: [],
  });
  const entity = CARD_TYPES?.OPPORTUNITY?.id == type ? 'opportunities' : 'tickets'
  const navigate = useNavigate();
  const onExecAction = (action: string, dataAction: any) => {
    loadingCardsByCompany(1, 10, []);
  }
  

  useEffect(() => {
    loadingCardsByCompany(1, 10, data)
  }, [data]);

  const loadingCardsByCompany = async (page = 1, limit = 10, data: any) => {
    let resultData: any = [...data]
    try {
      let query = `page=${page}&perPage=${limit}`
      let queryVars: any = Object.fromEntries(new URLSearchParams(location.search));
      if (queryVars.contactId) {
        const contactId = Number(queryVars.contactId);
        query = query + `&contactId=${contactId}`
      }

      let data: any = await getCards(type, query)
      if (data?.data?.length > 0) {
        resultData = resultData.concat(data?.data)
      }

      let uniqueIds = new Set()
      let uniqueData = resultData.filter((item: any) => {
        if (!uniqueIds.has(item.id)) {
          uniqueIds.add(item.id)
          return true
        }
        return false
      })

      setAllData({
        total_items: data?.pagination?.total,
        total_pages: data?.pagination?.pagesCount,
        current_page: data?.pagination?.page,
        data: uniqueData,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const openDetailModal = (id: any) => {
    let queryVars: any = Object.fromEntries(new URLSearchParams(location.search));
    let query = `?isEmbed=true`
    if (queryVars.contactId) {
      const contactId = Number(queryVars.contactId);
      query= query + `&contactId=${contactId}`
    }
    navigate(`/${entity}/details/${id}${query}`)
  }

  return (
    <KTCardBody className='py-4 '>
      {isLoading ? (
        <ListLoading />
      ) : allData.data.length === 0 ? (
        <p style={{ textAlign: 'center', fontSize: '16px', color: '#666' }}>Nenhum registro encontrado</p>
      ) : (
        <>
          {allData.data.map((item: any) => (
            <div className='card' onClick={(e: any) => {
              if (!e.target.closest('[data-role="ignore"]')) {
                openDetailModal(item.id);
              }
            }} key={item.id} style={{ cursor: 'pointer', border: '1px solid #ddd', borderRadius: '8px', padding: '10px', marginBottom: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <strong className='label-card'>{item.title}</strong>
                <div data-role="ignore" className='m-0'>
                  <button
                    disabled={false}
                    type='button'
                    data-role="ignore"
                    className='btn btn-sm '
                    style={{ padding: '0px 5px 5px 5px' }}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='right-start'
                  >
                    <i className='fa-solid fa-ellipsis-vertical label-card'></i>
                  </button>
                    <CardDropDownActions isMobile={true} card={item} onExecAction={onExecAction} />
                </div>
              </div>
              {item.contact && (
                <p className='label-detail-card' style={{ margin: '5px 0' }}>
                  <i className='fa-regular fa-id-badge' style={{ marginRight: '3px' }}></i> {item.contact.name}
                </p>
              )}

              {item.company && (
                <p className='label-detail-card' style={{margin: '5px 0' }}>
                  <i className='fa-regular fa-building' style={{ marginRight: '3px' }}></i> {item.company.name}
                </p>
              )}

              {item.responsible && (
                <p className='label-detail-card' style={{margin: '5px 0' }}>
                  <i className='fa-solid fa-user' style={{ marginRight: '3px' }}></i> {item.responsible.name}
                </p>
              )}

              {item.funnel && (
                <p className='label-detail-card' style={{margin: '5px 0' }}>
                  <i className='fa-solid fa-filter' style={{ marginRight: '3px' }}></i> {item.funnel.name}
                </p>
              )}

              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <span style={{
                  color: item.statusName == 'Perdido' || item.statusName == 'Não resolvido' ? '#FF0000' :
                    item.statusName == 'Ganho' || item.statusName == 'Resolvido' ? '#008000' :
                      item.statusName == 'Congelado' || item.statusName == 'Arquivado' ? '#808080' :
                        '#007bff',
                  fontWeight: 'bold'
                }}>
                  {item.statusName}
                </span>
              </div>
            </div>
          ))}
          {allData.total_pages > 0 && allData.total_pages > allData.current_page && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
              <button
                className='btn btn-sm btn-wl-custom-primary-collor'
                onClick={() => {
                  loadingCardsByCompany(allData.current_page + 1, 10, allData.data)
                }}
              >
                Carregar Mais Oportunidades
              </button>
            </div>
          )}
        </>
      )}
    </KTCardBody>
  )
}

export { CardsList }
