import {Modal} from 'react-bootstrap'
import {
  useCardSendEmailModal,
  useCardSendEmailModalDispatch,
} from '../../core/CardSendEmailModalContext'
import {CardSendEmailForm} from './CardSendEmailModalForm'
import 'react-quill/dist/quill.snow.css'
type Props = {}

const CardSendEmailModal = ({}: Props) => {
  const cardSendEmailModal = useCardSendEmailModal()
  const cardSendEmailModalDispatch = useCardSendEmailModalDispatch()

  if (!cardSendEmailModal.open) {
    document.body.classList.remove('modal-open')
    return <></>
  }

  document.body.classList.add('modal-open')

  const handleClose = async () => {
    if (cardSendEmailModal.card.onClose) {
      await cardSendEmailModal.card.onClose()
    }
    cardSendEmailModalDispatch({type: 'close-modal'})
  }

  return (
    <>
      <Modal
        size='xl'
        show={cardSendEmailModal.open}
        onHide={handleClose}
        onBackdropClick={handleClose}
        centered
        scrollable
        fullscreen='sm-down'
        contentClassName={'modal-form-manage'}
      >
        <Modal.Header closeButton>
          <h3 className='label-card'>
            <strong>Enviar E-mail</strong>
          </h3>
        </Modal.Header>
        <Modal.Body style={{padding: '0px'}}>
          {cardSendEmailModal.formAction == 'add' ? (
            <CardSendEmailForm
              card={cardSendEmailModal.card}
              onClose={handleClose}
              emailMessageProp={null}
              contact={cardSendEmailModal.contact}
            />
          ) : cardSendEmailModal.formAction == 'edit' ? (
            <CardSendEmailForm
              card={cardSendEmailModal.card}
              onClose={handleClose}
              emailMessageProp={cardSendEmailModal.emailMessage}
              contact={cardSendEmailModal.contact}
            />
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export {CardSendEmailModal}
