import {useEffect, useMemo, useState} from 'react'
import {KTCard, KTCardBody} from '../..'
import {EMAIL_MESSAGES_STATUS, EmailMessages} from '../../../../app/pages/cards/core/_models'
import {getEmailMessages} from '../../../../app/pages/cards/core/_requests'
import {ActionsCell} from './columns/ActionsCell'
import {Spinner} from 'react-bootstrap'
import {useCardSendEmailModalDispatch} from '../../../../app/pages/cards/core/CardSendEmailModalContext'
import {ListPaginationLocal} from './pagination/ListPaginationLocal'


const EmailMessageTable = ({entityData, entity}: any) => {
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const perPage = 10

  const getEmailMessagesData = async (pageToFetch = 1) => {
    setLoading(true)
    try {
      const result = await getEmailMessages(
        `page=${pageToFetch}&perPage=${perPage}&cardId=${entityData.id}`
      )
      setData(result)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getEmailMessagesData(page)
  }, [page])

  const cardSendEmailModalDispatch = useCardSendEmailModalDispatch()

  const openAddModal = () => {
    cardSendEmailModalDispatch({
      type: 'open-add-modal',
      data: {type: entity, id: entityData.id, onClose: () => getEmailMessagesData(page)},
      contactId: entityData.contact?.id,
    })
  }
  const openEditModal = (emailMessage: any) => {
    cardSendEmailModalDispatch({
      type: 'open-edit-modal',
      data: {
        type: entity,
        id: entityData.id,
        onClose: () => getEmailMessagesData(page),
        contactId: entityData.contact?.id,
      },
      emailMessage: emailMessage,
      contactId: entityData.contact?.id,
    })
  }

  const updatePage = (newPage: any) => {
    if (newPage !== page) {
      setPage(newPage)
    }
  }

  return (
    <>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid'>
          <div
            id='kt_page_title'
            data-kt-swapper='true'
            data-kt-swapper-mode='prepend'
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            className='page-title d-flex flex-wrap me-3 flex-row justify-content-center'
          >
            <h3 className='label-card'>
              <strong>E-mails</strong>
            </h3>
          </div>

          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <a
              href='#'
              onClick={openAddModal}
              className='btn btn-sm fw-bold btn-primary'
              style={{marginTop: 5}}
            >
              Adicionar
            </a>
          </div>
        </div>
      </div>

      <KTCard>
        {loading ? (
          <div style={{textAlign: 'center', padding: '20px'}}>
            <Spinner />
          </div>
        ) : !data || data.data.length === 0 ? (
          <p style={{textAlign: 'center', fontSize: '16px', color: '#666'}}>
            Nenhum registro encontrado
          </p>
        ) : (
          <KTCardBody className='py-4'>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th className='label-card'>Status</th>
                    <th className='label-card'>Data</th>
                    <th className='label-card'>Contato</th>
                    <th className='label-card'>Assunto</th>
                    <th className='label-card text-end'>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((row: any, index: any) => (
                    <tr
                      style={{cursor: 'pointer'}}
                      onClick={(e: any) => {
                        if (e.target?.localName == 'td') {
                          openEditModal(row)
                        }
                      }}
                      key={index}
                    >
                      {/* Status */}
                      <td
                        style={{
                          color:
                            row.status == EMAIL_MESSAGES_STATUS.FAILURE
                              ? 'red'
                              : row.status == EMAIL_MESSAGES_STATUS.SENT
                              ? 'green'
                              : 'gray',
                        }}
                      >
                        {(() => {
                          switch (row.status) {
                            case EMAIL_MESSAGES_STATUS.DRAFT:
                              return 'Rascunho'
                            case EMAIL_MESSAGES_STATUS.FAILURE:
                              return 'Erro no envio'
                            case EMAIL_MESSAGES_STATUS.SENT:
                              return 'Enviado'
                            case EMAIL_MESSAGES_STATUS.SENDING:
                              return 'Enviando'
                            default:
                              return ''
                          }
                        })()}
                      </td>
                      <td className='table-tr'>
                        {new Date(row.confirmedAt || row.createdAt).toLocaleDateString('pt-BR')}
                      </td>
                      <td className='table-tr'>{row.to}</td>
                      <td className='table-tr'>{row.subject}</td>
                      <td className='text-end'>
                        <ActionsCell
                          card={entityData}
                          cardEntity={entity}
                          entity={row}
                          refetch={getEmailMessagesData}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <ListPaginationLocal
              pagination={data.pagination}
              updatePage={updatePage}
              isLoading={loading}
            />
          </KTCardBody>
        )}
      </KTCard>
    </>
  )
}

export {EmailMessageTable}
