import {useQuery} from 'react-query'
import {FunnelsEditModalForm} from './FunnelsEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../../../modules/view/ListViewProvider'

type Props = {
  funnelStage: any
  onCloseModal: any
  funnel: any
}

const FunnelsEditModalFormWrapper = ({funnel, funnelStage, onCloseModal}: Props) => {
  const funnelStageData = funnelStage || {id: undefined}
  return (
    <FunnelsEditModalForm
      isLoading={false}
      funnel={funnel}
      funnelStage={funnelStageData}
      onCloseModal={onCloseModal}
    />
  )

  return null
}

export {FunnelsEditModalFormWrapper}
