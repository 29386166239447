import { KTIcon } from "../../../../../_metronic/helpers"
import { useIntegrationEmailFormModalDispatch } from "../core/IntegrationEmailFormModalContext"



const IntegrationsCard = () => {

    const integrationEmailFormModalDispatch = useIntegrationEmailFormModalDispatch()

    const openAddModal = () => {
        integrationEmailFormModalDispatch({
            type: 'open-add-modal'
        })
    }
    return (
        <div className="integration-cards-container">
            <div className="integration-card" onClick={openAddModal}>
                <i className={`fa fa-envelope fs-2`} style={{ marginRight: '8px' }}></i>
                <h3 className="m-0 p-0">{'Configurações de Email'}</h3>
            </div>
        </div>

    )
}

export { IntegrationsCard }
