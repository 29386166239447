/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect, useState } from "react"
import { MenuComponent } from "../../../../assets/ts/components"
import { KTSVG } from "../../../components/KTSVG"
import Swal from "sweetalert2"
import { deleteEmailById, sendEmailMessages } from "../../../../../app/pages/cards/core/_requests"
import { EMAIL_MESSAGES_STATUS } from "../../../../../app/pages/cards/core/_models"
import { useCardSendEmailModalDispatch } from "../../../../../app/pages/cards/core/CardSendEmailModalContext"


const ActionsCell: FC<any> = ({ entity, refetch, cardEntity, card }) => {
  const [loadingForm, setLoadingForm] = useState(false)
  const cardSendEmailModalDispatch = useCardSendEmailModalDispatch()
  const openEditModal = () => {
    cardSendEmailModalDispatch({
      type: 'open-edit-modal',
      data: { type: cardEntity, id: card.id, onClose: () => refetch() },
      emailMessage: entity,
      contactId: card.contact?.id
    })
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const sendEmailSubmit = async () => {
    setLoadingForm(true);
    try {
      const resultSendEmail = await sendEmailMessages(entity!.id)
      if (resultSendEmail.status == EMAIL_MESSAGES_STATUS.FAILURE) {
        Swal.fire('Erro ao enviar e-mail', '', 'error')
      } else {
        Swal.fire('E-mail enviado', '', 'info')
      }
      refetch()
    } catch (error: any) {
      setLoadingForm(false);
      let text = 'Erro ao enviar e-mail, tente novamente mais tarde...'
      if (error?.response?.data?.message) {
        text = error?.response?.data?.message
      }
      Swal.fire({
        title: 'Opss..',
        text: text,
        icon: 'error',
        confirmButtonText: 'OK',
      })
    } finally {
      setLoadingForm(false);
    }
  };


  const confirmDeleteEmail = () => {
    if (loadingForm) return
    Swal.fire({
      title: 'Excluir Email',
      text: 'Tem certeza que deseja excluir este e-mail? Esta ação é irreversível.',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then((result) => {
      if (result.isConfirmed) {
        execDeleteEmail(entity.id)
      } else if (result.isDenied) {

      }
    })
  }

  const execDeleteEmail = async (idEmail: any) => {
    setLoadingForm(true);
    try {
      await deleteEmailById(idEmail)
      Swal.fire('E-mail deletado!', '', 'success')
      refetch()
    } catch (error: any) {
      setLoadingForm(false);
      console.log(error)
      let text = 'Erro ao excluir e-mail, tente novamente mais tarde...'
      if (error?.response?.data?.message) {
        text = error?.response?.data?.message
      }
      Swal.fire({
        title: 'Opss..',
        text: text,
        icon: 'error',
        confirmButtonText: 'OK',
      })
    } finally {
      setLoadingForm(false);
    }
  }


  return (
    <>
      <a
        href="#"
        className={loadingForm ? 'disabled' : ''}
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={(e) => {
          if (loadingForm) {
            e.preventDefault();
          }
        }}
      >
        <KTSVG
          path="/media/icons/duotune/general/gen053.svg"
          className="svg-icon svg-icon-2x"
        />
      </a>
      {!loadingForm && (
        <div
          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 fw-bold fs-7 w-175px"
          data-kt-menu="true"
        >
          {entity.status == EMAIL_MESSAGES_STATUS.SENT ? (
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                onClick={async () => {
                  openEditModal();
                }}
              >
                Visualizar
              </a>
            </div>
          ) : (
            <>
              <div className="menu-item px-3">
                <a
                  className="menu-link px-3"
                  onClick={async () => {
                    await sendEmailSubmit();
                  }}
                >
                  Enviar
                </a>
              </div>
              <div className="menu-item px-3">
                <a
                  className="menu-link px-3"
                  onClick={() => {
                    confirmDeleteEmail();
                  }}
                >
                  Excluir
                </a>
              </div>
            </>
          )}
        </div>
      )}
    </>

  )
}

export { ActionsCell }

