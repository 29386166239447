import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ListLoading } from '../../../../modules/view/ListLoading'
import Swal from 'sweetalert2'
import { InputFieldSet } from '../../../../../_metronic/partials/inputs/InputFieldSet'
import { transferCardResponsible } from '../../core/_requests'
import SelectAsync from 'react-select/async';
import { useFunnelDispatch } from '../../../funnel/core/FunnelContext'
import { useCardTransferResponsibleModalDispatch } from '../../core/CardTransferResponsibleModalContext'
import { getResponsiblesTransfer } from '../../core/_requests'
import debounce from "debounce-promise";

type Props = {
  opportunity: any
}

const editDataSchema = Yup.object().shape({

})

const CardTransferResponsibleModalForm: FC<Props> = ({ opportunity }) => {

  const funnelDispatch = useFunnelDispatch();
  const cardTransferResponsibleModalDispatch = useCardTransferResponsibleModalDispatch();

  const [dataForEdit] = useState<any>({
    user: opportunity.responsible ? { label: opportunity.responsible.name, value: opportunity.responsible.id } : null
  })

  const usersOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = [];
      let users: any = await getResponsiblesTransfer(opportunity.funnelId?opportunity.funnelId:opportunity.funnel?.id, "page=1&perPage=20&search=" + encodeURIComponent(inputValue));
      /*if (users && users.data != undefined) {
        for (let i = 0; i < users?.data?.length; i++) {
          list.push({ label: users.data[i].name, value: users.data[i].id });
        }
      }*/
      if (users && users != undefined) {
        for (let i = 0; i < users.length; i++) {
          list.push({ label: users[i].name, value: users[i].id });
        }
      }
      resolve(list);
    });
  const debounceUserOptions = debounce(usersOptions, 500)


  const formik = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      if (!values.user || !values.user.value) Swal.fire({
        title: 'Opss..',
        text: 'Preencha os Campos corretamente.',
        icon: 'error',
        confirmButtonText: 'OK'
      })

      try {

        let opUpdated = await transferCardResponsible(opportunity.id, { newResponsibleId: values.user.value });

        if (funnelDispatch) {
          if (opUpdated) {
            opportunity.responsible = opUpdated.responsible;
          }

          funnelDispatch({
            type: "update-step-card",
            data: opportunity
          })
        }
        if(opportunity.onClose){
          await opportunity.onClose()
        }

        cardTransferResponsibleModalDispatch({
          type: "close-modal"
        })

        Swal.fire('Oportunidade transferida!', '', 'success')

      } catch (ex) {
        console.error(ex)
        Swal.fire({
          title: 'Opss..',
          text: 'Houve um problema ao transferir oportunidade.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_freeze_opportunity' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_freeze_opportunity_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_freeze_opportunity_header'
          data-kt-scroll-wrappers='#kt_modal_freeze_opportunity_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}

          <div className='fv-row mb-7'>
            <InputFieldSet
              label={"Responsável"}
            >
              <SelectAsync
                menuPortalTarget={document.body}

                placeholder={""}
                name="user"
                loadOptions={debounceUserOptions}
                value={formik.values.user}
                cacheOptions
                defaultOptions
                onChange={selectedOption =>
                  formik.setFieldValue("user", selectedOption ? selectedOption : null)
                }
                className="fieldset-input"
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderStyle: "none",
                    backgroundColor: undefined,
                    boxShadow: "none",
                  })
                }}
              />
            </InputFieldSet>
          </div>

        </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-10'>
          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Transferir Oportunidade</span>
            {(formik.isSubmitting) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting) && <ListLoading />}
    </>
  )
}

export { CardTransferResponsibleModalForm }
