import {FC} from 'react'

type Props = {
  status?: number
}

const StatusCell: FC<Props> = ({status}) => (
  <>
    {' '}
    {status == 1 ? (
      <>Ativo</>
    ) : (
      <>Inativo</>
    )}
  </>
)

export {StatusCell}
