import { FC, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useIntegrationEmailFormModal, useIntegrationEmailFormModalDispatch } from '../core/IntegrationEmailFormModalContext';
import { IntegrationEmailFormModal } from './IntegrationEmailFormModal';

interface IntegrationProps {
  userConfig?: boolean;
}
const IntegrationEmailModal : FC<IntegrationProps> = ({ userConfig })=> {
  const systemIntegrationEmailModal = useIntegrationEmailFormModal();
  const systemIntegrationEmailDispatch = useIntegrationEmailFormModalDispatch()


  const handleClose = () => {
    systemIntegrationEmailDispatch({
      type: 'close-modal'
    })
  };

  useEffect(() => {
    if (systemIntegrationEmailModal.open) {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }

  }, [systemIntegrationEmailModal.open])


  if (!systemIntegrationEmailModal.open) return <></>

  return (<>

    <Modal
      size="xl"
      show={systemIntegrationEmailModal.open}
      onHide={handleClose}
      onBackdropClick={handleClose}
      centered
      scrollable
      fullscreen='sm-down'
      contentClassName={"modal-form-manage"}
    >
      <Modal.Header closeButton>
        <h3><strong>Configurações de e-mail</strong></h3>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px" }}>
        <IntegrationEmailFormModal userConfig={userConfig?true:false} onClose={handleClose}></IntegrationEmailFormModal>
      </Modal.Body>
    </Modal></>
  )
}

export { IntegrationEmailModal }
