export const INTEGRATION_PROVIDER = {
    SMTP:1,
    GMAIL:2,
    MICROSOFT:3
}
export const INTEGRATION_TYPE = {
    EMAIL: 1,
};


export const INTEGRATION_STATUS = {
    DISABLED: 0,
    ACTIVATED:1
};

export type IntegrationEmail = {
  id: number
  status: number
  integrationId: number
  config: {
    host: string;
    port: number;
    emailAddress: string;
    displayName?: string;
    securityType: string;
    password: string;
  }
}
