import {Formik} from 'formik'
import {InputFieldSet} from '../../partials/inputs/InputFieldSet'
import Swal from 'sweetalert2'
import {getNotes} from '../../../app/pages/cards/core/_requests'
import {createNote} from '../../../app/pages/cards/core/_requests'
import {deleteNote} from '../../../app/pages/cards/core/_requests'
import {useState, useRef} from 'react'
import {Spinner} from 'react-bootstrap'
import FileList from './Components/FileList/Index'
import uniqid from 'uniqid'
import {editNote} from '../../../app/pages/cards/core/_requests'
import {useEffect} from 'react'
import {ButtonAddAttachment} from '../attachament-component/styles'
import {replaceUrlsToLinks, strHasLink} from '../strLinkHelper'

const AnnotationComponent = ({entityData, entity}: {entityData: any; entity: string}) => {
  const buttonStylesFile: any = {
    display: 'inline-block',
    padding: '4px 25px 4px 25px',
    backgroundColor: 'white',
    border: '1px solid #93a4ee',
    color: '#93a4ee',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
    position: 'relative',
    fontWeight: 'bold',
    top: '-2px',
  }
  const buttonStyles: any = {
    display: 'inline-block',
    padding: '4px 25px 4px 25px',
    // backgroundColor: '#93a4ee',
    // borderColor: '#0201c5',
    // color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    // transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
    position: 'relative',
    fontWeight: 'bold',
    top: '-2px',
    marginLeft: 'auto',
    // border: '1px solid #93a4ee',
  }
  const buttonLoadMoreStyles: any = {
    display: 'inline-block',
    padding: '4px 25px 4px 25px',
    backgroundColor: '#93a4ee',
    borderColor: '#0201c5',
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
    position: 'relative',
    fontWeight: 'bold',
    top: '-2px',
    border: '1px solid #93a4ee',
  }
  const [attachedFiles, setAttachedFiles] = useState<any>([])
  const [noteEditText, setNoteEditText] = useState<any>({
    entity_id: null,
    show: false,
    text: '',
  })
  const [noteData, setNoteData] = useState<any>({
    data: [],
    pagination: {},
  })

  const inputFileRef = useRef<any>(null)

  const execDeleteNote = async (note: any) => {
    try {
      await deleteNote(note.id)
      removeDataOnState(note?.id)
    } catch (e) {
      Swal.fire({
        title: 'Opss..',
        text: 'Houve um problema ao excluir o registro.',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const confirmDeleteNote = (note: any) => {
    Swal.fire({
      title: 'Excluir Anotação',
      text: 'Tem certeza que deseja excluir a anotação?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then((result) => {
      if (result.isConfirmed) {
        execDeleteNote(note)
      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  const deleteFile = (data: any) => {
    setAttachedFiles(attachedFiles.filter((file: any) => file.idFile !== data?.idFile))
  }

  const formatFileSize = (file: any): string => {
    const bytes = file?.size

    if (bytes === 0) return '0 Bytes'

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(1024))

    return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`
  }

  const handleStoreFile = (file: any) => {
    if (file?.size > 20 * 1024 * 1024) {
      Swal.fire({
        title: 'Atenção!',
        text: 'O arquivo excede 20MB. Por favor, escolha um arquivo menor.',
        icon: 'warning',
        confirmButtonText: 'Ok!',
      })
      return
    }

    if (attachedFiles.length == 3) {
      Swal.fire({
        title: 'Atenção!',
        text: 'É possível anexar 3 documentos por anotação.',
        icon: 'warning',
        confirmButtonText: 'Ok!',
      })
      return
    }
    const uploadedFile = {
      file,
      idFile: uniqid(),
      name: file?.name,
      readableSize: formatFileSize(file),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      old: false,
    }

    setAttachedFiles(attachedFiles?.concat(uploadedFile))
  }

  const handleViewAttachments = (attachments: any) => {
    if (!attachments || attachments?.length <= 0) return null
    const filesToRender: any = attachments?.map((file: any) => {
      if (file?.old) return file
      file.type = file.mimeType
      return {
        file,
        idFile: file?.id,
        name: file?.fileName,
        readableSize: null,
        preview: file?.downloadUrl,
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
        old: true,
        createdAt: file?.createdAt,
      }
    })
    return (
      <div className='mb-5'>
        {' '}
        <div style={{marginBottom: '2px'}}>
          <strong>
            Anexos <span style={{color: '#93a4ee'}}>({attachments?.length})</span>
          </strong>
        </div>{' '}
        <FileList files={filesToRender} />
      </div>
    )
  }

  const handleEditNote = async () => {
    try {
      await editNote(noteEditText.entity_id, {note: noteEditText.text})
      editDataOnState(noteEditText?.entity_id, noteEditText.text)
      setNoteEditText({
        show: false,
        text: '',
        entity_id: null,
      })
      // refetch()
    } catch (e) {
      console.log(e)
    }
  }
  const editDataOnState = (id: number, text: string) => {
    let newData = {...noteData}
    let itemsNote = newData?.data
    let entityEdit = itemsNote.find((e: any) => e?.id == id)
    if (entityEdit) {
      entityEdit.note = text
    }
    setNoteData(newData)
  }
  const addDataOnState = (created: any) => {
    let newData = {...noteData}
    let dataNotes = [...newData.data]
    dataNotes.push(created)
    created.attachments = [...attachedFiles]?.map((file) => {
      file.old = true
      return file
    })
    newData.data = dataNotes?.sort((a: any, b: any) => {
      const dateA: any = new Date(a.createdAt)
      const dateB: any = new Date(b.createdAt)
      return dateB - dateA
    })
    setNoteData(newData)
    setAttachedFiles([])
  }
  const removeDataOnState = (note_id: number) => {
    let newData = {...noteData}
    newData.data = newData?.data?.filter((e: any) => e?.id != note_id)
    setNoteData(newData)
  }
  const loadDataPage = async (page = 1, sizePerPage = 5) => {
    try {
      let query = `${entity}Id=${entityData?.id}&page=${page}&perPage=${sizePerPage}&sort=id&order=DESC&include_attachments&include_user=true`
      let result = await getNotes(query)
      let newData = {...noteData}
      if (page > 1) {
        newData.data = newData?.data?.concat(result?.data)
      } else {
        newData.data = result?.data
      }
      newData.pagination = result.pagination
      setNoteData(newData)
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    loadDataPage()
  }, [])

  return (
    <div>
      <h3 className='label-card'  style={{marginTop: '10px', marginBottom: '10px'}}>Anotações</h3>

      <Formik
        initialValues={{note: ''}}
        validationSchema={null}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          setSubmitting(true)

          if (!values.note || values.note.length <= 3) {
            Swal.fire({
              title: 'Opss..',
              text: 'Insira uma anotação com mais de 3 caracteres.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
            return
          }
          try {
            const formData = new FormData()
            formData.append(entity + 'Id', entityData?.id)
            formData.append('note', values.note)
            if (attachedFiles?.length > 0) {
              for (let i = 0; i < attachedFiles?.length; i++) {
                let use = attachedFiles[i]
                if (use['file']) {
                  formData.append('files', use['file'])
                }
              }
            }

            let created = await createNote(formData)

            addDataOnState(created)
            resetForm()
          } catch (ex) {
            console.error(ex)
            Swal.fire({
              title: 'Opss..',
              text: 'Houve um problema ao salvar o formulário.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {(props) => (
          <form
            id='kt_modal_add_note_form'
            className='form'
            onSubmit={props.handleSubmit}
            noValidate
          >
            <div className='fv-row mb-2'>
              <InputFieldSet label={'Anotação'}>
                <textarea
                  onChange={(e) => {
                    const target = e.target
                    target.style.height = `${target.scrollHeight}px`
                    props.handleChange(e)
                  }}
                  onBlur={props.handleBlur}
                  value={props.values.note}
                  placeholder=''
                  name='note'
                  className={'form-control fieldset-input text-area-note'}
                  autoComplete='off'
                  style={{paddingRight: '3.5rem'}}
                />
              </InputFieldSet>
            </div>
            <div style={{display: 'flex', flexFlow: 'wrap'}}>
              <button
                type='button'
                className='btn-sm btn-wl-custom-primary-collor-inverse-border'
                onClick={() => {
                  if (inputFileRef?.current) {
                    inputFileRef?.current?.click()
                  }
                }}
              >
                Adicionar Anexo
              </button>
              <input
                type='file'
                onChange={(e: any) => {
                  handleStoreFile(e?.target?.files[0])
                }}
                style={{display: 'none'}}
                ref={inputFileRef}
              />

              <button
                className='btn btn-sm btn-wl-custom-primary-collor'
                type='button'
                style={{position: 'relative', top: '-2px', marginLeft: 'auto', fontWeight: 'bold'}}
                onClick={() => {
                  if (!props.isSubmitting) {
                    props.handleSubmit()
                  }
                }}
              >
                {props?.isSubmitting ? <Spinner size='sm' color='white' /> : 'Salvar'}
              </button>
            </div>

            {attachedFiles?.length > 0 ? (
              <>
                {' '}
                <h4 style={{marginTop: '10px', marginBottom: '3px'}}>Anexos</h4>{' '}
                <FileList files={attachedFiles} deleteFile={deleteFile} />
              </>
            ) : null}

            {/* <button
                type='submit'
                className='btn btn-sm btn-primary'
                disabled={props.isSubmitting || !props.isValid || !props.touched}
              >
                <span className='indicator-label'>Enviar</span>
                {props.isSubmitting && (
                  <span className='indicator-progress'>
                    Por Favor Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button> */}
          </form>
        )}
      </Formik>

      <div style={{marginTop: '20px'}}>
        {noteData?.data?.map((note: any) => (
          <>
            {noteEditText.show && noteEditText.entity_id == note?.id ? (
              <>
                <div className='fv-row mb-2 mt-5'>
                  <h3>Editar Anotação - #{note?.id}</h3>
                  <InputFieldSet label={'Anotação'}>
                    <textarea
                      onChange={(e: any) => {
                        let data = {...noteEditText}
                        data.text = e?.target?.value
                        setNoteEditText(data)
                      }}
                      value={noteEditText.text}
                      placeholder=''
                      name='note'
                      className={'form-control fieldset-input text-area-note'}
                      autoComplete='off'
                      style={{paddingRight: '3.5rem'}}
                    />
                  </InputFieldSet>
                </div>
                <div
                  style={{display: 'flex', flexFlow: 'wrap', justifyContent: 'end', gap: '16px'}}
                >
                  <div style={{justifyContent: 'end', alignSelf: 'center'}}>
                    {' '}
                    <strong
                      className='btn btn-sm btn-wl-custom-link btn-wl-custom-hover-danger'
                      onClick={() => {
                        setNoteEditText({
                          show: false,
                          entity_id: null,
                          text: '',
                        })
                      }}
                    >
                      Cancelar
                    </strong>
                  </div>
                  <div
                    className='btn btn-sm btn-wl-custom-primary-collor'
                    onClick={async () => {
                      handleEditNote()
                    }}
                    // style={{ ...buttonStyles, marginLeft: '' }}
                  >
                    Salvar
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='mb-5'>
                  <div className='note-card'>
                    <div className='d-flex justify-content-between label-card'>
                      <p>
                        <strong>Anotação</strong>
                      </p>
                      <div style={{display: 'flex', flexFlow: 'wrap', gap: '10px'}}>
                        <i
                          onClick={() => {
                            setNoteEditText({
                              show: true,
                              text: note?.note,
                              entity_id: note?.id,
                            })
                          }}
                          className='fa-solid fa-edit fs-2 me-0 '
                          style={{
                            cursor: 'pointer',
                            marginLeft: 'auto',
                            color: 'var(--wl-btn-background-primary-collor)',
                          }}
                        ></i>
                        <i
                          onClick={() => confirmDeleteNote(note)}
                          className='fa-solid fa-circle-xmark fs-2 me-1'
                          style={{cursor: 'pointer', marginLeft: 'auto', color: '#606161'}}
                        ></i>
                      </div>
                    </div>
                    <div>
                      <p className='label-card' style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                        <div dangerouslySetInnerHTML={{__html: replaceUrlsToLinks(note.note)}} />
                      </p>
                    </div>
                    <div>{handleViewAttachments(note?.attachments)}</div>
                  </div>
                  <div className='label-card'><strong > {note.user?.name}</strong> - {new Date(note.createdAt).toLocaleString()}</div>
                  
                </div>
              </>
            )}
          </>
        ))}
      </div>
      {noteData?.pagination?.page < noteData?.pagination?.pagesCount ? (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
          {' '}
          <button
            className='btn btn-sm btn-wl-custom-primary-collor'
            onClick={() => {
              const newPage = noteData?.pagination?.page + 1
              loadDataPage(newPage)
            }}
          >
            Carregar Mais Anotações
          </button>
        </div>
      ) : null}
      {/* {notes?.pagination ? (
        <Pagination
          pagination={notes?.pagination}
          onPageChange={(target: number) => {
            if (target == 1 && notes?.pagination?.page == 1) return
            setCurrentPage(target)
            queryClient.setQueryData(getNotesQueryKeys, {page: target})
          }}
        />
      ) : null} */}
    </div>
  )
}

export default AnnotationComponent
