import {useDrop} from 'react-dnd'

const FunnelStepDroppable = ({
  step,
  children,
  moveCard,
}: {
  step: any
  children: any
  moveCard: any
}) => {
  const [{isOver}, dropRef] = useDrop({
    accept: 'card',
    drop: (item: any) => {
      moveCard(item, step.id)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  return (
    <div
      ref={dropRef}
      key={step.id}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 113px)',
        ...(isOver ? {backgroundColor: 'rgb(0 78 200 / 34%)'} : {}),
        ...(step.color
          ? {
              borderTop: `4px solid ${step.color}`,
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
            }
          : {
              borderTop: `4px solid rgb(173,207,210)`,
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
            }),
      }}
    >
      {children}
    </div>
  )
}

export default FunnelStepDroppable
