import { CardsListSearchComponent } from './CardsListSearchComponent'
import { CardsListFilter } from './CardsListFilter'
import { useCardFormModalDispatch } from '../../core/CardFormModalContext'
import { CARD_TYPES } from '../../core/_models'

const CardsListHeader = ({type, embed}: {type: number, embed?:boolean}) => {

  const cardFormModalDispatch = useCardFormModalDispatch();

  const openAddModal = () => {
    cardFormModalDispatch({
      type: 'open-add-primary-data-modal',
      data: {
        type: type
      }
    })
  }

  const loadTitle = () => {
    let title = "";
    switch(type)
    {
      case CARD_TYPES.OPPORTUNITY.id:
        title = "Oportunidades";
        break;
      case CARD_TYPES.TICKET.id:
        title = "Tíckets";
        break;
    }
    return title;
  }

  return (
    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
      <div id="kt_app_toolbar_container" className="d-flex flex-stack container-fluid">

        <div id="kt_page_title" data-kt-swapper="true" data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className="page-title d-flex flex-wrap me-3 flex-row justify-content-center">
          {!embed && (<i className="fa-solid fa-filter fs-2 me-1"></i>)}
          {!embed && (<h3><strong>{loadTitle()}</strong></h3>)}
        </div>
        <div className='d-flex align-items-center gap-2 gap-lg-3'>
        {!embed && (
            <>
              <CardsListSearchComponent />

              <CardsListFilter cardType={type} />
            </>
          )}

          <a
            href='#'
            onClick={openAddModal}
            className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
            style={{marginLeft: "3rem",position: "relative",top: "2px"}}
          >
            Adicionar
          </a>
        </div>
      </div>
    </div>
  )
}

export { CardsListHeader }
